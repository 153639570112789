#SafetyAssessment{}
#SafetyAssessment .img_1{

  margin:-200px 0 0 0;
  width:200px;
  height:200px;
  z-index :9999;
  background-image: url("./img/高风险.png") !important;
  background-size: 200px 200px
}
#SafetyAssessment .img_2{
  margin:-200px 0 0 0;
  width:200px;
  height:200px;
  z-index :9999;
  background-image: url("./img/低风险.png") !important;
  background-size: 200px 200px
}
