.logo {
    height: 40px;
    background-color: rgba(17, 17, 17, 0.616);
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
}

.site-layout .site-layout-background {
    background: #fff;
}

.site-drawer-render-in-current-wrapper {
    position: absolute;
    height: 90vh;
    width: 15vw;
    padding: 48px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
  }

  .menu_sider .ant-drawer-body {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}
.ant-menu-sub.ant-menu-inline{
    background-color: #ffffff !important;
    margin: 0 !important;
}
.column-left .ant-menu-title-content{
    font-size: 16px !important;
    font-weight: bold;
    color: #678DD7 !important;
}
.column-left .ant-menu-title-content a{
    font-size: 16px !important;
    font-weight: bold;
}
.column-left  .ant-menu-title-content font{
    font-weight: 500;
}
.topLink .ant-menu-title-content{
    font-size: 14px !important;
}
.ant-menu-title-content a{
    font-size: 14px !important;
    font-weight: 500;
}
.ant-menu-title-content{
    font-size: 14px !important;
    font-weight: 500;
}
.ant-menu-item-selected {
    background-color: #F9F9FA !important;
    color: #1F1F1F !important;
}
.ant-menu-item-selected a{
    background-color: #F9F9FA !important;
    color: #1F1F1F !important;
    font-size: 16px !important;
}
.ant-menu-inline .anticon{display: none !important;}
/* 下拉箭头样式 */
.ant-menu-submenu-arrow {
  color:#678DD7 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-arrow {
    color:#034890 !important;
  }

/* .ant-menu-submenu-selected{
    background-color: #E8F2FF !important;
    color: #034890 !important;
} */
.column-left .ant-menu-submenu-selected  .ant-menu-title-content{
    color: #034890 !important;
}
.ant-menu-submenu-selected font{
    color: #034890 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-arrow {
    color:#1F1F1F !important;
  }
  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after{
      display: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
      height: 52px !important;
      line-height: 52px !important;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 52px !important;
    line-height: 52px !important;
  }

#homeAmplify .amplify_div{
    padding-top: 10px;
    position: absolute;
    transition: 0.5s;
    float: left;
    top:50%;
    width:18px;
    height:40px;
    border-radius: 0 15px 15px 0  ;
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
    z-index: 99999;
}
#homeAmplify .amplify_div:hover{
    top:49.8%;
    /*padding-top: 11px;*/
    width:20px;
    height:42px;
    transition: 0.5s;
    cursor:pointer;
    float:bottom;

}
