html{overflow: hidden;}
body {
  margin: 0;
  font-family: "Microsoft YaHei" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Microsoft YaHei" !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td{
border-color: #ddd !important;
}
th{text-align: center !important;}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table{
  border-color: #ddd !important;
}
.ant-table.ant-table-bordered > .ant-table-container{border-color: #ddd !important;}
/* .ant-table th{text-align: center !important;} */

.topBtnBox{width: 100%; background: #fff; padding: 10px 30px;}
.topBtnBox span{color: #1890FF; margin-right: 20px; cursor: pointer;}
.topBtnBox span span{margin-right: 5px; color: #1890FF;}
.topSearchBox{width: 100%; background: #fff; padding: 30px 30px 20px 30px; margin-bottom: 10px;}
.topSearchBox h3{font-size: 24px; font-weight: bold; border-left: 10px solid #1F1F1F; color: #1F1F1F; height: 24px; line-height: 24px; padding-left: 15px; margin-bottom: 30px;}
.ant-btn{min-width: 80px;}
.topSearchBox .ant-row:nth-child(odd){margin-bottom: 10px;}
.topTitleBox{width: 100%; background: #fff; padding: 0 10px 10px;}
.topTitleBox h3{font-size: 24px; font-weight: bold; border-left: 10px solid #1F1F1F; color: #1F1F1F; height: 24px; line-height: 24px; padding-left: 15px; padding-bottom: 30px; float: left;}
.topTitleBox .addList{float: right;}
.topTitleBox .addLink{color:#1890FF; margin-left: 20px; line-height: 28px; font-size: 14px; cursor: pointer;}
.topTitleBox .addLink span{margin-right: 5px;}
.orderTableBox{background: #fff;}
.orderTableBox .ant-layout{width: 49%; float: left;}
.orderTableBox .ant-layout:nth-child(odd){border-right: 20px solid #F9F9FA; width: 50%;}
.orderTableBox .ant-layout:nth-child(even){width: 50%;}

.topSearchOrderBox{width: 50%; background: #fff; padding: 30px 30px 20px 30px; display: inline-block; vertical-align: top;}
.br{border-right: 20px solid #F9F9FA;}
.topSearchOrderBox h3{font-size: 24px; font-weight: bold; border-left: 10px solid #1F1F1F; color: #1F1F1F; height: 24px; line-height: 24px; padding-left: 15px; margin-bottom: 30px;}
.topSearchOrderBox button{min-width: 70px !important; padding: 0 10px;}
/* .ant-pagination-options{display: none !important;} */
.stepsBox{width: 70%; margin:0 0 30px 20px;}
.stepsBox .ant-steps-item-icon{background: #6CCB55 !important; border: 1px solid #6CCB55 !important;}
.stepsBox .ant-steps-icon{color: #fff !important;}
.stepsBox .ant-steps-item-title{color: #6CCB55 !important;}
.stepsBox .ant-steps-item-wait .ant-steps-item-icon{background: #B8B8B8 !important; border: 1px solid #B8B8B8 !important;}
.stepsBox .ant-steps-item-wait .ant-steps-item-title{color: #B8B8B8 !important;}
.ant-layout .orderPanl{background: #fff !important;}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{background-color:#B8B8B8 !important;}
.topTitleBox h4{font-size: 24px; font-weight: bold; color: #525252; height: 54px; line-height: 54px; padding-left: 25px; background: #F9F9FA;}
.pyTableBox{margin: 0 10px 30px;}
.pyTable{border: 1px solid #E0E0E0; width: 100%; line-height: 56px;}
.pyTable th{border: 1px solid #E0E0E0; text-align: center;}
.pyTable td{border: 1px solid #E0E0E0; padding: 0 10px; min-width: 80px; text-align: center;}
.pyTable td input{border: 0; width: 100% !important;}
.ant-tree-title{color: #1F1F1F !important;}
.ant-tree-list .anticon{color: #1F1F1F !important;}
.orderIcon{font-size: 24px !important; margin-right: 10px; position: relative; top: 3px;}
.leftTopBtn{border: 1px solid #E0E0E0; width: 90%; margin: 10px 5%; font-size: 14px; line-height: 40px; padding: 0 20px; text-align: left; color: #B8B8B8; border-radius: 20px;}
.leftTopBtn span{float: right; line-height: 44px;}


.side-menu .ant-tree-title{color: #fff !important;}
.side-menu .ant-tree-list .anticon{color: #fff !important;}

.downloadLink span{display: inline-block; cursor: pointer; width: 150px; line-height: 30px; color: #fff; border-radius: 3px; border:1px solid #1890FF; background: #1890FF; margin: 0 10px;}
.ant-form-item-label > label{font-size: 16px !important; color:#1f1f1f !important;}

.ant-space-item button{position: relative; top: -3px;}

.leftTopBtnText{padding-right: 30px; text-overflow:ellipsis; white-space: nowrap; overflow: hidden;}
.leftTopBtnIcon{position: absolute; right: 10px; top: 12px;}

.tableTopSearchBox .ant-row{flex-flow:nowrap !important}


.workbenchTitle h3{font-size: 18px; font-weight: bold; border-left: 5px solid #1F1F1F; color: #1F1F1F; height: 18px; line-height: 18px; padding-left: 10px;}
.workbenchTitle h3 a{float: right; font-size: 14px; font-weight: 500;}
.workbenchListNum li{width: 20%; display: inline-block; background: #f9fafd; margin: 10px 5% 10px 0; padding: 20px; border-radius: 5px;}
.workbenchListNum li span{display: block; text-align: center; color: #b4b4b5; line-height: 40px; font-size: 16px;}
.workbenchListNum li b{display: block; text-align: center; color: #333333; line-height: 40px; font-size: 24px;}
.workbenchListImg li{width: 150px; height: 150px; text-align: center; margin: 20px 100px 0 0; display: inline-block;}
.workbenchListImg li img{border-radius: 100%; width: 100px;}
.workbenchListImg li span{display: block; line-height: 50px; font-size: 18px; color: #666;}
.workbenchMsgList li{border-bottom: 1px solid #ccc; line-height: 38px; color: #666;}
.workbenchMsgList li:last-child{border: 0;}
.workbenchMsgList li b{color: #333;}
.workbenchMsgList li span{color: #999; float: right; font-size: 14px;}
.workbenchCardList li{border: 1px solid #f2f4fa; border-radius: 3px; width: 32%; float: left; margin-right: 2%; height: 200px; margin-top: 10px; position: relative;}
.workbenchCardList li:nth-child(3n-3){margin-right: 0;}
.workbenchCardList li .cardListNum{color: #333; padding: 8px 10px 0 10px; line-height: 30px;}
.workbenchCardList li .cardListName{color: #88a3cd; padding: 0 10px; line-height: 30px;}
.workbenchCardList li .cardListName span{display: inline-block; width: 99%;}
.workbenchCardList li p{color: #999; padding: 0 10px; line-height: 30px; margin: 0;}
.workbenchCardList li .cardListBtn{background: #f5f9ff; border-top: 1px solid #f2f4fa; position: absolute; bottom: 0; width: 100%; height: 36px; line-height: 36px;}
.workbenchCardList li .cardListBtn a{display: inline-block; width: 49.5%; text-align: center; border-right: 1px solid #f2f4fa;}
.workbenchCardList li .cardListBtn a:last-child{border: 0;}


.picAndTextList li{width: 22%; float: left; margin-right: 1.5%; text-align: center; line-height: 36px;}
.picAndTextList li img{width: 80%; min-height: 144px; border: 1px solid #ccc; padding: 3px; margin: 0 auto; display: block;}
.picAndTextBox h4{clear: both; font-size: 14px; border-top: 1px solid #ccc; padding-top: 10px; font-weight: bold;}
.picAndTextTop{padding-bottom: 15px;}
.picAndTextTop p{margin: 0; padding: 0; font-size: 14px;}
.picAndTextTop p span{width: 49%; display: inline-block; line-height: 32px;}

.picAndTextInnerTop{padding-bottom: 15px;}
.picAndTextInnerTop p{margin: 0; padding: 0; font-size: 14px;}
.picAndTextInnerTop p span{width: 49%; display: inline-block; line-height: 32px;}

.collectionDetailBox .topTitleBox{width: 100%; background: #fff; padding: 0 10px 10px;}
.collectionDetailBox .topTitleBox h3{font-size: 16px; font-weight: bold; border-left: 5px solid #1F1F1F; color: #1F1F1F; height: 16px; line-height: 16px; padding-left: 10px; padding-bottom: 0; margin: 10px 0 20px 0; float: left;}
.collectionDetailBox .topTitleBox .addList{float: right;}
.collectionDetailBox .topTitleBox .addLink{color:#1890FF; margin-left: 20px; line-height: 28px; font-size: 14px; cursor: pointer;}
.collectionDetailBox .topTitleBox .addLink span{margin-right: 5px;}


.rTable table{width: 100%; line-height: 30px; margin: 0 5px; box-sizing: border-box;}
.rTable table th{font-size: 14px; font-weight: bold; background: #f5f5f5; border: 1px solid #ccc; line-height: 36px;}
.rTable table td{font-size: 14px; border: 1px solid #ccc; padding: 0 10px; line-height: 30px; text-align: center;}
.autoTableBox{overflow: auto; width: 98%; margin: 0 auto; height:calc(100% - 150px);}
.autoTableBox td{ min-width: 130px;}
.autoTableBox tr td:first-child{ min-width: 300px; text-align: left;}


.leftFormBox input{background: #fff !important; color: #000;}
.leftFormBox .ant-select-selector{background: #fff !important; color: #000;}
.leftFormBox .ant-select-selection-item{color: #000 !important;}
.leftFormBox .ant-input{color: #000 !important;}

.leftFormBox table{width: 96%; line-height: 30px; margin: 15px 2%;}
.leftFormBox table th{font-size: 14px; font-weight: bold; background: #f5f5f5; border: 1px solid #ccc; line-height: 36px;}
.leftFormBox table td{font-size: 14px; border: 1px solid #ccc; padding: 0 10px; line-height: 30px; text-align: center;}
.topFormBox .ant-select-selection-overflow{overflow: hidden; height: 30px;}


/*加载中*/
.loadBox{width: 100%; height: 100%; position: fixed; top: 60px; left: 270px; display: none;}
.loadBox .loadInner{width: 100%; height: 100%; position: fixed; top: 60px; left: 270px; background: #000; opacity: 0.3; z-index: 99;}
.loadBox .loadIcon{position: absolute; left: 45%; top: 40%; width: 80px; height: 100px; z-index: 999; text-align: center;}
.loadBox .loadIcon p{font-size: 16px; color: #fff; text-align: center;}
/*加载中*/

.topSearchBox .ant-form-item{margin-bottom: 0 !important;}

.rTimeBox .ant-pagination{display: none !important;}


.ant-radio-inner{border-color: #000 !important;}

.mapLinkBox{padding:0 15px;}
.mapLinkBox h4{font-size: 24px; border-bottom: 1px solid #ccc; line-height: 48px;}
.mapLinkBox a{color: #1890FF; display: inline-block; min-width: 200px; margin: 15px 15px 15px 0; border: 1px solid #1890FF; border-radius: 3px; line-height: 36px; text-align: center; font-size: 16px;}




.autoTableBoxFix table {
  border-collapse: collapse;
  margin: 0;
}

.autoTableBoxFix {
  overflow: auto;
  height:calc(100% - 200px); /* 设置固定高度 */
  border-top: 1px solid #ccc;
  width: 97%;
  left: 1.5%;
  position: absolute
}
.autoTableBoxFix td{background: #fff;}
.autoTableBoxFix td, .autoTableBoxFix th {
  /* 设置td,th宽度高度 */
  border: 1px solid #c7d8ee;
  width: 150px;
  min-width: 150px;
  height: 30px;
  padding: 5px;
}

.autoTableBoxFix th {
  padding: 0;
  position: sticky;
  top: -2px; /* 首行永远固定在头部  */
}

.autoTableBoxFix td:first-child, .autoTableBoxFix th:first-child {
  position: sticky;
  left: 0; /* 首列永远固定在左侧 */
  z-index: 1;
}

.autoTableBoxFix th:first-child {
  z-index: 2; /*表头的首列要在上面*/
}
.autoTableBoxFix th p{padding: 0; margin: 0; line-height: 40px; font-weight: bold; color: #000; border-bottom: 1px solid #ccc;}
.autoTableBoxFix th div {
  width: 33.3333%;
  float: left;
  border-right: 1px solid #ccc;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}
.autoTableBoxFix th div:last-child{
  border-right: 0;
}


/*APP功能列表*/
.funCheckList{padding: 30px;}
.funCheckList label{width: 150px !important; margin-bottom: 20px !important;}



/*安装详情*/
.installBox{height: 760px; padding: 10px; overflow: auto;}
.installList{min-height: 120px;}
.installList h4{border-bottom: 1px solid #ccc; color: #1890FF; padding-bottom: 10px; font-size: 16px;}
.installList h4 span{background: #1890FF; color: #fff; display: inline-block; width: 20px; height: 20px; line-height: 20px; text-align: center; border-radius: 100%; margin-right: 10px;}
.installInner ul li{width: 25%; display: inline-block; line-height: 30px; color: #999;}
.installInner ul li span{color: #333;}
.installInnerImgBox img{width: 11%; margin-right: 1%; margin-bottom: 15px; border: 1px solid #1890FF; padding: 5px; border-radius: 5px;}


.installInnerSmall{margin-bottom: 10px; border-radius: 5px; padding: 10px;}
.installInnerSmall p{color: #1890FF; margin-bottom: 10px;}
.installInnerSmall ul li{width: 25%; display: inline-block; line-height: 30px; color: #999;}
.installInnerSmall ul li span{color: #333;}

.bigImgBox{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999; display: none; text-align: center;}
.installAltText{line-height: 60px; font-size: 30px; color: #999; text-align: center;}
.bigImgBoxBg{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #000; opacity: 0.3;}
.bigImgBox img{position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); max-width: 96%; max-height: 96%; border: 5px solid #fff; border-radius: 10px;}

.detailSimListBox{width: 100%;}
.detailSimListBox h4{color: #1890FF; border-bottom: 1px solid #ccc;}
.detailSimListBox table{width: 100%; line-height: 20px; box-sizing: border-box;}
.detailSimListBox table th{font-size: 12px; font-weight: bold; background: #f5f5f5; border: 1px solid #ccc; line-height: 30px;}
.detailSimListBox table td{font-size: 12px; border: 1px solid #ccc; padding: 0 10px; line-height: 24px; text-align: center;}
.trBox textarea{height: 400px !important; max-height: 400px !important;}

.storeBox{width: 24%; position: relative; height: 98%; border: 1px solid #ccc; float: left; margin:0 0.5%;}
.storeCkList h4{font-size: 16px; text-align: center; line-height: 60px; border-bottom: 1px solid #ccc;}
.storeCkList label{width: 90%; padding: 10px 20px 0;}


.installInner ul li table{width: 200px; line-height: 20px; box-sizing: border-box; display:inline-flex; position: relative; top: 10px;}
.installInner ul li table th{font-size: 12px; font-weight: bold; background: #f5f5f5; border: 1px solid #ccc; line-height: 30px;}
.installInner ul li table td{font-size: 12px; border: 1px solid #ccc; width: 100px; padding: 0 10px; line-height: 24px; text-align: center;}

.submissionDataBox .installInnerSmall{margin: 10px; border-radius: 5px; padding: 10px; border: 1px solid #1890FF;}
.submissionDataBox .installInnerSmall ul{margin: 0 !important; padding: 0 !important;}

.topTextList ul li{line-height: 36px; float: left; width: 33.33%; padding-left: 20px;}
