#middleType .ant-table-thead > tr th {
  font-size: 14px;
  color: #fff !important;
  padding: 7px 0.6vw !important;
  background-color: #033246!important;
  border: none!important;
}
#middleType .ant-table-tbody > tr > td {
  color: #fff!important;
  border: none!important;
}
#middleType .ant-table-cell-scrollbar {
  box-shadow:none!important;
}
#middleType .oddRow{
  background:#001b29  !important;
}
#middleType .evenRow{
  background:#033246  !important;
}

//表格奇数
#middleType .ant-table-tbody > tr:nth-child(even) > td {
  background: #002130  !important;
}
// 控制奇数得鼠标悬浮时间
#middleType .ant-table-cell-row-hover{
  background:#033246  !important;
}
#middleType .ant-empty-description{
  color: #fff;
}
