#workOrdersProcess{
  width:100%;
  height: 100%;
}
// 标题
#workOrdersProcess .workOrdersProcess_topSearchBox{
  width: 100%;
  height:80px;
  background: #ffffff;
  padding: 30px 30px 1px 30px;
}
// 标题
#workOrdersProcess .workOrdersProcess_topSearchBoxH3{
  font-size: 24px;
  font-weight: bold;
  border-left: 10px solid #1F1F1F;
  color: #1F1F1F;
  height: 24px;
  line-height: 24px;
  padding-left: 15px;
  margin-bottom: 20px;
  float:left
}
// 每个框-文字框
#workOrdersProcess .workOrdersProcess_css_div_card-header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}
// 每个框-文字框-文字
#workOrdersProcess .workOrdersProcess_css_div_card-title {
  width: 50%;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
// 文字省略
#workOrdersProcess .workOrdersProcess_text_div_css_1{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
// 下载按钮
#workOrdersProcess .workOrdersProcess_Button_div_css_1{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  border: 1px #cccccc solid;
  border-radius: 2px;
  font-size: 18px;

}
#workOrdersProcess .workOrdersProcess_Button_div_css_1:hover{
  border: 1px #4a8fd9 solid;
  color:  #4a8fd9;
}


// 工单详情--------------1
#workOrdersProcess .workOrdersProcess_css_div_head_1{
  width: 100%;
  height: 500px;
  padding: 20px 0 20px 0;
}
// 搜索框-内框
#workOrdersProcess .workOrdersProcess_css_div_head_1_div_frame{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

}
// 处理记录-----------------2
#workOrdersProcess .workOrdersProcess_css_div_head_2{
  width: 100%;
  height: 350px;
  padding: 20px 0 20px 0;
}
// 处理记录-内框
#workOrdersProcess .workOrdersProcess_css_div_head_2_div_frame{
  width: 100%;
  height: 98%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

}
// 处理结果-----------------3
#workOrdersProcess .workOrdersProcess_css_div_head_3{
  width: 100%;
  padding: 20px 0 50px 0;
}
// 处理结果-内框
#workOrdersProcess .workOrdersProcess_css_div_head_3_div_frame{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

}
