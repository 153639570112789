#warningAnimation{


}
@keyframes warn1 {
  0% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.0;
  }
  25% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.8;
  }
  75% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 0.0;
  }
}
@keyframes warn2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* 产生动画（向外扩散变大）的圆圈 第二个圆 */
.pulse1 {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 40%;
  top: 10%;
  //border: 1px solid #F56C6C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  animation: warn1 2s linear 1s infinite;
  box-shadow: 0px 0px 35px 25px #f52822 inset; /* 阴影效果 */

}
.pulse2 {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 40%;
  top: 10%;
  //border: 1px solid #F56C6C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  animation: warn1 2s linear 2s infinite;
  box-shadow: 0px 0px 35px 25px #f52822 inset; /* 阴影效果 */
}
.pulse3 {
  position: absolute;
  //background-color: #b4b4b5;
  //left: 45%;
  //top: 10%;
  width: 100vw;
  height: 100vh;
  animation: warn2 2s linear 1s infinite;
  box-shadow: 0px 0px 25px 15px #f52822 inset;
  z-index: 1;
}
