#AlarmHandleInfo{
  background-color: #ffffff;
}
// 上方样式
#AlarmHandleInfo .dvi_top{
  width: 100%;
  height: 200px;
}
// 上方样式-左侧样式
#AlarmHandleInfo .dvi_top_left{
  float: left;
  width: 60%;
  height: 100%;
}
#AlarmHandleInfo .dvi_top_left_li{
  width:100%;
  height:25px;
  margin:5px
}
// 上方样式-右侧样式
#AlarmHandleInfo .dvi_top_right{
  float: left;
  width: 40%;
  height: 100%;

}

#AlarmHandleInfo .top_separate_div{
  width: 99.5%;
  height: 1px;
  background-color: rgb(240 242 245);
  margin-left: 4px;
  float: left;
}

#AlarmHandleInfo .dvi_below{
  width: 100%;
  height: 550px;
}
#AlarmHandleInfo .dvi_below_div{
  width: 60%;
  height: 100px;
}
#AlarmHandleInfo .dvi_below_div_iamge{
  margin-left: 10px;
  float: left;
  width: 100px;
  height: 100px;
  border: 0.1px #999999 solid !important;
}
