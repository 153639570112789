 
.tree{
    .ant-tree{
        &-treenode {
            height: 4vh !important;
            align-items: center !important;
            padding: 0 !important;
            &::before{
                height: 4vh;
            }
        }
        &-switcher-icon{
            line-height: 4vh;
        }
    }
}