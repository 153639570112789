#bottomTwo{
  width: 100%;
  height: 100%;

}
#bottomTwo .bottomTwo_data_div_1_img_1{
  width: auto;
  height: 100%;
  padding-top: 28%;
  background-image: url(../../dataPanel/img/蓝色.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#bottomTwo .bottomTwo_data_div_1_img_2{
  width: auto;
  height: 100%;
  padding-top: 28%;
  background-image: url(../../dataPanel/img/编组 18备份.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#bottomTwo .bottomTwo_data_div_1_img_3{
  width: auto;
  height: 100%;
  padding-top: 28%;
  background-image: url(../../dataPanel/img/编组 18备份 2.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
}
#bottomTwo .bottomTwo_data_div_1_img_4{
  width: auto;
  height: 100%;
  //position:absolute;
  padding-top: 28%;
  background-image: url(../../dataPanel/img/蓝色.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
