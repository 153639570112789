.b_title{
    font-size: 1.2rem;
    position: absolute;
    left: 1vw;
    top: 0.5vh;
    z-index: 99
}
.installation_left_table{
    float: left;
    width: 33.5%;
    height: 90%;
}
.installation_right_table{
    float: right;
    width: 66%;
    height: 100%;

}
.cardList{float: left; width: 49%; margin: 10px 1% 10px 0; border-radius: 5px; background: #f5f5f5; padding: 10px; min-height: 260px;}
.cardList .ant-col-6{display: block; flex: 0 0 50%; max-width: 50%;}
.historicalTabel .ant-table-thead > tr > th, .historicalTabel .ant-table-tbody > tr > td, .historicalTabel .ant-table tfoot > tr > th, .historicalTabel .ant-table tfoot > tr > td{padding: 5px !important;}
