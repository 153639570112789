#middleMap1{}
#middleMap1 .middleMap_head_type{
  width: 400px;
  height:30px;
  position: absolute;
  z-index: 999;
  text-overflow: ellipsis; white-space: nowrap;
}
//按钮样式
#middleMap1 .middleMap_head_buttom_1{
  width: 28%;
  height: 100%;
  border-radius: 2px;
  border: 1px #428CFF ridge;
  cursor: pointer;
  background-color:#0B1F55;
  opacity:0.8;
  text-align: center;
  padding: 3px;
  float: left;
  color:#fff;
}
//按钮样式
#middleMap1 .middleMap_head_buttom_2{
  width: 28%;
  height: 100%;
  border-radius: 2px;
  border: 1px #fff ridge;
  cursor: pointer;
  background-color: rgb(35 123 201);
  opacity:0.8;
  text-align: center;
  padding: 3px;
  float: left;
  color: #fff;

}
#middleMap1 .middleMap_type_1{
  width:100vw;
  height:100vh;
  z-index:9;
}
#middleMap1 .middleMap_type_2{
  width:100vw;
  height:100vh;
  z-index:9;
}
