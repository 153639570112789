#bottomOne{
  width: 100%;
  height: 100%;
}
#bottomOne .bottomOne_data_div_1_img_1{
  width: 82%;
  height: 95%;
  background-position: center;
  background-image: url(../../dataPanel/img/浓度.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto   !important;
}
#bottomOne .bottomOne_data_div_1_img_2{
  width: 82%;
  height: 95%;
  background-position: center;
  background-image: url(../../dataPanel/img/压力.png) !important;
  background-repeat: no-repeat;
  background-size: 100% auto   !important;
}
#bottomOne .bottomOne_data_div_1_img_3{
  width: 80%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/流量.png) !important;
  background-repeat: no-repeat;
  background-size:  100%  auto !important;

}
#bottomOne .bottomOne_data_div_1_img_4{
  width: 80%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/温度.png) !important;
  background-repeat: no-repeat;
  background-size:  100%  auto !important;
}
#bottomOne .bottomOne_data_div_2_img_1{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/场站.png) !important;
  background-repeat: no-repeat;
  background-size:  100%  auto !important;
}
#bottomOne .bottomOne_data_div_2_img_2{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/充装站.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_2_img_3{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/阀井.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_2_img_4{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/调压柜.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_3_img_1{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/窨井.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_3_img_2{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/门站.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_3_img_3{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/气瓶.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}
#bottomOne .bottomOne_data_div_3_img_4{
  width: 95%;
  height: 100%;
  background-position: center;
  background-image: url(../../dataPanel/img/管盯.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  auto !important;
}

#bottomOne .bottomOne_data_div_button_1{
  width: 18%;
  height: 90%;
  background-color:#002545;
  text-align: center;
  padding-top:1%;
  float:left;
}
#bottomOne .bottomOne_data_div_button_2{
  width: 18%;
  height: 90%;
  background-color:rgb(35 123 201);
  text-align: center;
  padding-top:1%;
  float:left;
  border: 1px rgb(89 144 195) ridge;
  border-radius: 2px;
}
