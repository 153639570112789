#dever_map_demo{
  width: 1920px;
  height: 1080px;
}
#dever_map_demo .dever_map_demo_div{
  width: 100%;
  height: 100%;
  //background-color: #fa8c16;

}
#dever_map_demo .dever_map_demo_div_image{
  height:  100%;
  width: 100%;
  background-image: url(../../deviceMap/image/2.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
  padding-top: 10px;
}
// 标题--
#dever_map_demo .head_div{
  width: 100%;
  height: 7.5%;
}
// 标题图片
#dever_map_demo .head_div_image{
  margin-top: -12px;
  width: 100%;
  height: 100%;
  background-image: url(../../deviceMap/image/1.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
}
// 标题字体
#dever_map_demo .head_div_text{
  margin-left: 43%;
  margin-top: -65px;
  width: 100%;
  height: 100%;
  background-image: url(../../deviceMap/image/3.png) !important;
  background-repeat: no-repeat;
  background-size: 14% !important;
}
// 标题end--

// 内容--
#dever_map_demo .body_div{
  width: 100%;
  height: 92.5%;
  //background-color: #61dafb;
}
#dever_map_demo .body_left_div{
  float: left;
  width: 19%;
  height: 100%;
  //background-color: #999999;
}
#dever_map_demo .body_left_div_top{
  margin: 5.5px 0 0 5.5px;
  width: 359px;
  height: 459px;
  //background-color: #1890ff;
}
#dever_map_demo .body_left_div_top_image{
  width: 100%;
  height: 100%;
  background-image: url(../../deviceMap/image/4.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
}
#dever_map_demo .body_left_div_echart_heBing{
  width: 56%;
  height: 120px;
  float: left;
}
#dever_map_demo .body_left_div_echart{
  width: 28%;
  height: 120px;
  float: left;
}
#dever_map_demo .body_left_div_bottom{
  margin: 20px 0 0 5.5px;
  width: 359px;
  height: 499px;
}
#dever_map_demo .body_left_div_bottom_image{
  width: 100%;
  height: 100%;
  background-image: url(../../deviceMap/image/检定信息_维护信息背景图.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
}



#dever_map_demo .body_center_div{
  float: left;
  width: 61%;
  height: 100%;
  //background-color: #7cb305;
}
#dever_map_demo .body_center_div_bottom_div{
  width: 1154px;
  height: 251px;
  background-color: #88a3cd;
  margin-left: 15px;

}
//#dever_map_demo .body_center_div_bottom_image{
//  width: 100%;
//  height: 100%;
//  background-image: url(../../deviceMap/image/表格背景.png) !important;
//  background-repeat: no-repeat;
//  background-size: 100% !important;
//}
//#dever_map_demo .ant-table-thead .ant-table-cell {
//  background-image: url(../../deviceMap/image/表格标头.png) !important;
//  background-repeat: no-repeat;
//  background-size: 100% !important;
//  //background-color: green;
//}





#dever_map_demo .body_right_div{
  float: left;
  width: 353px;
  height: 980px;
  //background-color: #999999;
}
#dever_map_demo .body_right_div_image{
  margin: 5px 0 0 15px;
  width: 100%;
  height: 100%;
  background-image: url(../../deviceMap/image/设备在线率.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;

}




// 内容END--
