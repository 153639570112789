#middleMap{}
#middleMap .middleMap_head_type{
  width: 400px;
  height:30px;
  position: absolute;
  z-index: 999;
  text-overflow: ellipsis; white-space: nowrap;
}
//按钮样式
#middleMap .middleMap_head_buttom_1{
  width: 28%;
  height: 100%;
  border-radius: 2px;
  border: 1px #428CFF ridge;
  cursor: pointer;
  background-color:#0B1F55;
  opacity:0.7;
  text-align: center;
  padding: 3px;
  float: left;

}
//按钮样式
#middleMap .middleMap_head_buttom_2{
  width: 28%;
  height: 100%;
  border-radius: 2px;
  border: 1px #428CFF ridge;
  cursor: pointer;
  background-color: #95e2ff;
  opacity:0.7;
  text-align: center;
  padding: 3px;
  float: left;

}
