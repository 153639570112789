#devInstallation{
  //width: 1630px;
  //height: 1060px;
}
#devInstallation .formText_div{
  width: 50%;
  height: 15%;

}
#devInstallation .formText_div_span{
  width: 33%;
  display: inline-block;
}
#devInstallation .formText_installInner{
  padding-left: 5px;
  width: 33%;
  height: 100%;
  float: left;
}

#devInstallation .formText_installInner ul li{  line-height: 30px; color: #000000;}
#devInstallation .formText_installInner ul li span{color: #666666;}

// 竖线
#devInstallation .verticalLine{
  background-color: #d9d9d9;
  margin-top:2px;
  height: 416px;
  width: 1px;
  float: left;

}

#devInstallation .horizontalLine{
  margin-left: 2px;
  background-color: #d9d9d9;
  height: 1px;
  width: 99%;
}
