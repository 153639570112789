#orderGC{

}
.orderXinAo_TopCaoZuoLan_div{
  width: 100%;
  height: 150px;
  background-color: #ffffff;
  padding: 0.1%;

}
.orderXinAo_TopCaoZuoLan_info_div{
  width: 100%;
  height: 100%;
  padding: 0.5% 0.5% 2% 1%;
  background-color: #ffffff;
  border:1px solid #cccccc;

}
//
.orderXinAoLeftTableData_div{
  width: 22.5%;
  height: 80.5%;
  margin: 0.4%;
  float: left;
  background-color: #ffffff;
}
.orderXinAoRightTableData_div{
  width: 76%;
  height: 50.5%;
  margin: 0.5% 0 0.5% 0;
  float: left;
  background-color: #ffffff;
}
.orderXinAo_modal_div{
  margin-right: 10px;
  float: left;
  width: 32.2%;
  height: 600px;
  background-color: #ffffff;
 }
.orderXinAo_modal_directoryTree_div{
  margin: 1%;
  width: 98%;
  height: 95%;
  border:1px solid #cccccc;
  background-color: #ffffff;
}
//标签页样式

//.ant-tabs-ink-bar {
//  visibility: hidden;
//}

#orderGC .ant-radio-inner {
  border-width: 3px !important;
  width: 18px !important;
  height: 18px !important;
}
#orderGC .ant-radio-inner {
  border-color:rgba(64,149,229,1) !important;
}
