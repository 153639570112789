#deviceConfiguration{}
#deviceConfiguration .collapse_Descriptions_3_div{
  float: left;
  width: 210px!important;
  height: 60px;
  border: 1px  #d9d9d9 solid !important;
  padding: 15px 0 0 25px;
}
#deviceConfiguration .collapse_Descriptions_3_div:hover{
  background-color: #39b3d7;
  .ant-typography{
    color: rgba(255, 255, 255, 0.85)
  }
#deviceConfiguration .collapse_Descriptions_3_input_div{
    color: black;
  }
}
