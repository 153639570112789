#shopping{
  width: 49.5%;
  height: 80.5%;
}
.ant-checkbox-wrapper{
  margin: -3px !important;
}
tr.ant-table-measure-row{
  visibility: collapse;
}
.Conditions_div{
  padding: 0.5%;
}
.button_div{
  float: left;
}
.table_div{
  max-width: 39vm;
  width: 92%;
  word-break: keep-all;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.font_div{
  font-size: 1.5rem;
  padding-left: 35%;
}
.div_font_Conditions{
  padding: 0.5%;
}

.leftTableData_div{
  width: 49.5%;
  height: 80.5%;
  margin: 0.4%;
  float: left;
  background-color: #ffffff;
}
.leftTableData_button_div{
  padding-top: 0.5%;
  padding-bottom: 0.5%;

}
.leftTableData_Top_div1{
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  border-right: 1px  rgb(240 240 240) solid;
  border-left: 1px  rgb(240 240 240) solid;
  border-top: 1px  rgb(240 240 240) solid;

}
.leftTableData_Title_div{
  padding: 2px;
}
//------------------------------------------------
.rightTableData_div{
  width: 49%;
  height: 50.5%;
  margin: 0.5% 0 0.5% 0;
  float: left;
  background-color: #ffffff;
}
.rightTableData_font_div{
  width: 100%;
  height: 40px;
  padding-top: 0.5%;
  padding-bottom: 0.5%;

}
.rightTableData_botton_div{
  float: left;
}
.rightTableData_Top_div{
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  border-right: 1px  rgb(240 240 240) solid;
  border-left: 1px  rgb(240 240 240) solid;
  border-top: 1px  rgb(240 240 240) solid;
}
.rightTableData_Title_div{
  padding: 2px;
}
//------------------------------------------------------------
.Modal_title_div{
  font-size: 18px;
  padding-left: 5%;

}
.table-color-dust {
  background-color: #999999;
}
.table-color-dust:hover {
  background-color: #999999;
}
.table-color-dust:hover >.ant-table-cell{
  color: #f5222d;

}
.table-color-dust>.ant-table-cell {
  color: #f0f0f0;
}
