#areaTree{
  width: 100%;
  height: 100%;
}
#areaTree .body_div{
  width: 100%;
  height: 100%;
}
#areaTree .body_left_div{
  width: 50%;
  height: 100%;
  padding: 1%;
}
#areaTree .body_left_interior_div{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px rgb(240 242 245) solid;
}

#areaTree .body_left_interior_botton_div{
  width: 100%;
  height: 40px;
  padding: 1%;
}

#areaTree .body_left_interior_tree_div{
  width: 100%;
  height: 95%;
  padding: 1%;
}
#areaTree .body_left_interior_tree__span{

  .span{

  }:hover{
       color: #1890ff;
  font-size: 15px;
     }
}
