#InstallationInfo{
  width: 100%;
  height: 100%;
}

#InstallationInfo .formText_div{
  width: 60%;
  height: 12%;
  padding: 5px 0 0 10px;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#InstallationInfo .formText_1_installInner ul li{
  width: 45%;
  display: inline-block;
  line-height: 25px;
  color: #000000;
  font-weight: bolder;
  font-size: 16px
}
#InstallationInfo .formText_1_installInner ul li span{
  color: #666666;
  font-size: 14px;
  font-weight: initial;
}
