
@media screen and (min-device-width:1440px){
    // 长沙新奥燃气
    .login-body {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/loginbackgroundimg.jpg) !important; // 标准云端登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    // 汉德史特
    .login-body_1 {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/login-background-img.jpg) !important;// 汉德史特登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    // 无logo
    .login-body_2 {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/login-background-img-NoLogo.jpg) !important;// 汉德史特登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    // 无logo
    .login-body_3 {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/login-background-img2.jpg) !important;// 汉德史特登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    .login-main {
        width: 525px;
        height: 486px;
        position: fixed;
        top: 50%;
        margin-top: -225px;
        right: 280px;
        border: 1px solid #ffffff;
        border-radius: 10px;
        background-color:#fff;
        .ant-input-affix-wrapper-lg {
            border-radius: 5px;
        }
    }
    .login-main-title{
        text-align: center;
        height: 75px;
        font-size: 2em;
        line-height: 90px;
        font-weight: 550;
        color: #ffffff;
    }
    .login-main-bottom-text{
        text-align: center;
        color: #ffffff;
        margin: 30px 0;
        font-size: 1rem;
    }
    .login-main-bottom-text_1{
        //padding-top: 10px;
        text-align: center;
        //height: 11vh;
        font-size: 1rem;
        //line-height: 11vh;
        color: #cdcdcd;
    }
}

@media screen and (min-device-width:1680px){
    // 标准云端
    .login-body {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/loginbackgroundimg.jpg) !important;// 标准云端登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    // 汉德史特
    .login-body_1 {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: flex-end;
        top: 0;
        left: 0;
        flex-direction: row !important;
        background-image: url(../../assets/login-background-img.jpg) !important;;// 汉德史特登入图
        background-repeat: no-repeat;
        background-size: 100% !important;
    }
    .login-main {
        background-color: rgba(0,0,0,10);
        width: 500px;
        height: 480px;
        position: fixed;
        top: 50%;
        margin-top: -225px;
        right: 280px;
        border: 1px solid #ffffff;
        border-radius: 10px;
        background-color: #fff;
        .ant-input-affix-wrapper-lg {
            border-radius: 5px;
        }
    }
    .login-main-title{
        text-align: center;
        height: 11vh;
        font-size: 2em;
        line-height: 11vh;
        font-weight: 550;
        color: #ffffff;
    }
    .login-main-bottom-text{
        text-align: center;
        color: #ffffff;
        margin: 6vh 0;
        font-size: 1rem;
    }
}


@media screen and (min-device-width:1920px){
    //标准云端
    .login-body {
        height: 100vh;
        width: 100vw;
        flex-direction: row !important;
        background-image: url(../../assets/loginbackgroundimg.jpg) !important;// 标准云端登入图
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;
    }
    //汉德史特
    .login-body_1 {
        height: 100vh;
        width: 100vw;
        flex-direction: row !important;
        background-image: url(../../assets/login-background-img.jpg) !important;// 汉德史特登入图
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;
    }
    .login-main {
        width: 500px;
        height: 480px;
        position: fixed;
        top: 50%;
        margin-top: -225px;
        right: 280px;
        border: 1px solid #ffffff;
        border-radius: 10px;
        background-color: #fff;
        .ant-input-affix-wrapper-lg {
            border-radius: 5px;
        }
    }
    .login-main-title{
        text-align: left;
        height: 100px;
        font-size: 24px;
        line-height: 100px;
        font-weight: 550;
        color: #0065A7;
        padding-left: 40px;
    }
    .login-main-bottom-text{
        text-align: center;
        color: #B8B8B8;
        margin: 55px 0;
        font-size: 14px;
    }
}
