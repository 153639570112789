@font-face {
  font-family: "font_family"; /* Project id 3562759 */
  src: url('iconfont.woff2?t=1673059037379') format('woff2'),
       url('iconfont.woff?t=1673059037379') format('woff'),
       url('iconfont.ttf?t=1673059037379') format('truetype');
}

.font_family {
  font-family: "font_family" !important;
  font-size: 18px !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.icon-shebeixinxiguanli:before {
  content: "\e640";
}

.icon-shangchuanshujuicon:before {
  content: "\e63f";
}

.icon-dingdanguanliicon1:before {
  content: "\e63e";
}

.icon-cangkuguanli:before {
  content: "\e63c";
}

.icon-biaojujiandingicon:before {
  content: "\e63d";
}

.icon-zhengqibaobiaoicon1:before {
  content: "\e639";
}

.icon-simka:before {
  content: "\e63a";
}

.icon-zhengqibaobiaoicon:before {
  content: "\e637";
}

.icon-taizhangicon:before {
  content: "\e638";
}

.icon-IKITxitongguanliicon:before {
  content: "\e636";
}

.icon-baojingqijiandingguanli:before {
  content: "\e635";
}

.icon-zhengqishujukuicon:before {
  content: "\e634";
}

.icon-wangguan2:before {
  content: "\e633";
}

.icon-iktguanli:before {
  content: "\e631";
}

.icon-baogao:before {
  content: "\e630";
}

.icon-baobiaochaxunicon:before {
  content: "\e62f";
}

.icon-shebeixinxiguanliicon:before {
  content: "\e62d";
}

.icon-yongqidianxinxiguanliicon:before {
  content: "\e62e";
}

.icon-baojingguanliicon:before {
  content: "\e621";
}

.icon-baojingqijiandingguanliicon:before {
  content: "\e622";
}

.icon-biaojudaoruguanliicon:before {
  content: "\e623";
}

.icon-biaojuxinxiguanli:before {
  content: "\e624";
}

.icon-caigouguanliicon:before {
  content: "\e625";
}

.icon-ditushiliicon:before {
  content: "\e626";
}

.icon-erweimaguanliicon:before {
  content: "\e627";
}

.icon-gongyingshangbiaojuguanli:before {
  content: "\e628";
}

.icon-henjiguanliicon:before {
  content: "\e629";
}

.icon-kehuguanxiicon:before {
  content: "\e62a";
}

.icon-quanxianguanliicon:before {
  content: "\e62b";
}

