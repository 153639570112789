#createWorkOrder{
  width:100%;
  height: 100%;
}
#createWorkOrder .createWorkOrder_topSearchBox{
  width: 100%;
  height:80px;
  background: #ffffff;
  padding: 30px 30px 1px 30px;
}
// 标题
#createWorkOrder .createWorkOrder_topSearchBoxH3{
  width: 84%;
  font-size: 24px;
  font-weight: bold;
  border-left: 10px solid #1F1F1F;
  color: #1F1F1F;
  height: 24px;
  line-height: 24px;
  padding-left: 15px;
  margin-bottom: 20px;
  float:left
}
#createWorkOrder .createWorkOrder_css_div_head_1{
  width: 100%;
  height: 950px;
  padding: 20px 0 20px 0;
}
#createWorkOrder .createWorkOrder_css_div_head_1_div_frame{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  padding: 30px;

}
