#mapManagePageBody{
  width: 100vw;
  height: 100vh;

}
#mapManagePageBody .spen_css_1{
  float: left;
  color:#70b7ff;
  width: 40px;
  cursor:pointer;
  :hover{
    font-size: 14.3px;
    color: #4f3bff;
    transition: 0.2s;
  }
}
#mapManagePageBody .spen_css_2{
  float: left;
  color:#70b7ff;
  width: 40px;
  cursor:pointer;
  :hover{
    font-size: 14.3px;
    color: #ff2239;
    transition: 0.2s;
  }
}
// 中间-左边样式-内部样式
#mapManagePageBody .body_left_css_1{
  //cursor:pointer;
  position: absolute;
  z-index: 999;
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  float: left;
  width:350px;                  // 长
  height:700px;               // 宽
  margin-top:4%;              // 离顶
  margin-left: 2%;            // 离左
  border-radius: 3px;         // 圆角
  border: 1px #cccccc solid;  // 边框
  transition: 0.5s;           // 动画
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
  background-color: rgba(255, 255, 255, 0.8);

}

#mapManagePageBody .selectOneType{
  border: 1px rgba(141, 197, 255,0.5) solid;  // 边框
  transition: 0.5s;           // 动画
  box-shadow: 1px 1px 10px 1px rgba(141, 197, 255, 0.5); // 底阴
}
