#newConfigurePage{
  background-color: #ffffff;

}
// 顶部分割线
#newConfigurePage .top_separate_div{
  width: 99%;
  height: 1px;
  background-color: #999999;
  margin-left: 4px;
  float: left;
}
#newConfigurePage .formText_1_div{
  width: 350px;
  padding-top: 1%;
  padding-left: 1%;
  float: left;
}
#newConfigurePage .formText_2_div{
  width: 700px;
  padding-top: 1%;
  padding-left: 1%;
  float: left;
}
#newConfigurePage .formText_1_installInner ul li{
  line-height: 30px;color: #000000;font-weight: bolder;
}
#newConfigurePage .formText_1_installInner ul li span{color: #666666;}


#newConfigurePage .bottom_div{
  width: 100%;
  height: 750px;
  background-color: #ffffff;
}
#newConfigurePage .bottom_separate_div{
  width: 1px;
  margin-top : 5px;
  height: 85%;
  background-color: #999999;
  float: left;
}
#newConfigurePage .bottom_left_div{
  padding : 10px;

  width: 49.6%;
  float: left;
}
#newConfigurePage .bottom_right_div{
  padding : 10px;
  width: 49.6%;
  float: left;
}
#newConfigurePage .bottom_button_div{
  float: left;
  height: 50px;
  width: 100%;

}
#newConfigurePage .bottom_button_css{
  float: right;
  margin: 10px;
}
#newConfigurePage .bottom_div2{
  width: 100%;
  height: 850px;
  background-color: #ffffff;

}
