#AddSelfInspectionDetails{}
#AddSelfInspectionDetails .img_1{
  width: 280px;
  height: 280px;
  position: absolute;
  z-index :999;
  margin: 5% 0 0 50%;
  background-image: url(../industrySecurity/img/合格.png)!important;
  background-repeat: no-repeat;
  text-align: center;

}
#AddSelfInspectionDetails .img_2{
  width: 280px;
  height: 280px;
  position: absolute;
  //position: relative;
  z-index :999;
  margin: 5% 0 0 50%;
  background-image: url(../industrySecurity/img/不合格.png)!important;
  background-repeat: no-repeat;
  text-align: center;
}
