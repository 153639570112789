.drawerForm .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
    color: rgb(82 79 79 / 85%) !important;
}
.drawerForm .ant-form-item-control-input-content {
    color: black !important;
}

.drawerForm .ant-form-item-control-input {
    align-items: flex-start !important;
}

.drawerForm .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0 !important;
}