#bottomFive{
  width: 100%;
  height: 100%;

}
#bottomFive .bottomFive_head_type{}
#bottomFive .bottomFive_data_type_2_table_div_1{
   width:100%;
   height:12%;
   background-color:rgb(5 42 76/40%);
   font-size:12px;
   padding-top: 2px;
 }
#bottomFive .bottomFive_data_type_2_table_div_2{
  width:100%;
  height:12%;
  background-color:rgb(6 48 84/40%);
  font-size:12px;
  padding-top: 2px;
}
