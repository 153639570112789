#gasDataPanel1{
  width: 100vw;
  height: 100vh;
  background-color:#020F1C;

}
// 整体-----------------------------------------
#gasDataPanel1 .body_type{
  width: 100vw;
  height: 100vh;

}
//顶部-----------------------------------------
#gasDataPanel1 .head_type{
  width:100%;
  height:11%;
}
//顶部---背景图
#gasDataPanel1 .head_type_img{

  width:100%;
  height:100%;
  background-image: url(./img/head.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
#gasDataPanel1 .head_type_right_img_1 {
  width: 35%;
  height:25px;
  float: left;
  background-image: url(./img/多云@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
}
#gasDataPanel1 .head_type_right_img_2 {
  width: 28%;
  height: 25px;
  float: left;
  background-image: url(./img/矢量智能对象.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
}

//中部-----------------------------------------
#gasDataPanel1 .middle_type{
  width:100%;
  height:53%;
}
//中部---左
#gasDataPanel1 .middle_left_type{
  width: 27%;height: 100%;
  padding-left: 2%;
  float: left;
}
//中部---左---背景图
#gasDataPanel1 .middle_left_img{
  width: 98%;height: 98%;
  background-image: url(./img/左上框.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;

}
// 大小按钮样式-1
#gasDataPanel1 .middle_middle_MiddleMap_type_1{
  position: absolute;
  z-index: 999;
  width: 25px;
  height: 25px;
  padding:3px;
  border: 1px #1763ef ridge;
  background-size: auto 100% !important;
  right:28%;
  cursor: pointer;
}
// 大小按钮样式-2
#gasDataPanel1 .middle_middle_MiddleMap_type_2{
  position: absolute;
  z-index: 999;
  width: 25px;
  height: 25px;
  padding:3px;
  border: 1px #1763ef ridge;
  background-size: auto 100% !important;
  right:0;
  cursor: pointer;
}

//警告栏
#gasDataPanel1 .middle_middle_warningBar_div_1{
  width:40%;
  height:50px;
  z-index:99999;
  position: absolute;
  top:17%;
  left: 29.5%;
  border: 1px #1763ef ridge;
}
//警告栏
#gasDataPanel1 .middle_middle_warningBar_div_2{
  width:40%;
  height:50px;
  z-index:99999;
  position: absolute;
  top:8%;
  left: 29.5%;
  border: 1px #1763ef ridge;
}
//按钮样式
#gasDataPanel1 .middle_middle_warningBar_div_buttom{
  width: 80%;
  height: 90%;
  border-radius: 10px;
  border: 1px #428CFF ridge;
  cursor: pointer;
  background-color:#0B1F55;
  opacity:0.7;
  text-align: center;
  padding: 3px;
  float: left;

}


//中部---中---样式1
#gasDataPanel1 .middle_middle_type_1{
  width: 45%;height: 98%;
  z-index: 998;
  background-color: #d9363e;
  float: left;
  text-overflow: ellipsis; white-space: nowrap;
  overflow: hidden
}
//中部---中---样式2
#gasDataPanel1 .middle_middle_type_2{
  width: 100vw;height: 100vh;
  z-index: 999;
  background-color: #d9363e;
  position: absolute;
  text-overflow: ellipsis; white-space: nowrap;
  overflow: hidden
}
//中部---右
#gasDataPanel1 .middle_right_type{
  width: 27%;height: 100%;
  padding-left: 8px;
  float: left;
  //background-color: #b4b4b5;
}
//中部---右---背景图
#gasDataPanel1 .middle_right_img{
  width: 98%;height: 98%;

  background-image: url(./img/右上框.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
//底部-----------------------------------------
#gasDataPanel1 .bottom_type{
  width:100%;
  height:43%;
}
//底部---1
#gasDataPanel1 .bottom_type_1{
  width: 27%;
  height:100%;
  float: left;
  padding-left: 2%;
}
//底部---1---背景图
#gasDataPanel1 .bottom_type_1_img{
  width: 98%;height: 80%;
  background-image: url(./img/左下框.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}
//底部---2
#gasDataPanel1 .bottom_type_2{
  width: 22.5%;
  height:100%;
  float: left;
  //padding-top: 10px;
  padding-left: 1%;

}
//底部---2---背景图
#gasDataPanel1 .bottom_type_2_img{
  width: 98%;height: 80%;
  background-image: url(./img/中下框.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}
//底部---3
#gasDataPanel1 .bottom_type_3{
  width: 22.5%;
  height:100%;
  float: left;
  padding-left: 0.5%;
}
//底部---3---背景图
#gasDataPanel1 .bottom_type_3_img{
  width: 94%;height: 80%;
  background-image: url(./img/中下框.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}
//底部---4
#gasDataPanel1 .bottom_type_4{
  width: 27%;
  height:100%;
  float: left;
  padding-left: 0.5%;
}
//底部---4---背景图
#gasDataPanel1 .bottom_type_4_img{
  width: 98%;height: 80%;
  background-image: url(./img/右下框.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}
