#middleRight_2{
  width: 100%;
  height: 100%;

}
#middleRight_2 .middleRight_head_type{
  width: 100%;
  height: 12%;
  padding: 20px 0 0 50px;
  //background-color: #d9363e;
}
#middleRight_2 .middleRight_head_type_title{
  font-size: 22px;
  color:#BAE9FF;
  font-family: 方正兰亭黑简体;

}
//内容整体
#middleRight_2 .middleRight_data_type{
  width: 100%;
  height: 90%;
  //background-color: #92d989;
}
//一排
#middleRight_2 .middleRight_data_type_1_title{
  font-size: 14px;
  color:#FFFFFF;
}
#middleRight_2 .middleRight_data_type_1_div_1{
  width: 20%;
  height: 100%;
  float: left;
  margin: 0 45px 0 58px;
}
#middleRight_2 .middleRight_data_type_1_div_1_img{
  width: 100%;
  height: 100%;
  padding-top: 45%;
  background-image: url(../../dataPanel/img/重大隐患.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}


#middleRight_2 .middleRight_data_type_1_div_2{
  width: 20%;
  height: 100%;
  float: left;
  margin: 0 44px 0 0;
}
#middleRight_2 .middleRight_data_type_1_div_2_img{
  width: 100%;
  height: 100%;
  padding-top: 45%;
  background-image: url(../../dataPanel/img/一级隐患.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}


#middleRight_2 .middleRight_data_type_1_div_3{
   width: 20%;
   height: 100%;
   float: left;

 }
#middleRight_2 .middleRight_data_type_1_div_3_img{
  width: 100%;
  height: 100%;
  padding-top: 45%;
  background-image: url(../../dataPanel/img/二级隐患.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#middleRight_2 .middleRight_data_type_1_div_4_img{
  width: 100%;
  height: 100%;
  padding: 3.5%;
  background-image: url(../../gasDataPanel/img/表具@4x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}


//二排
#middleRight_2 .middleRight_data_type_2{}
#middleRight_2 .middleRight_data_type_2_table_div_1{
  width:80%;
  height:24%;
  background-color:rgb(5 42 76/50%);
  //background-color:rgb(6 48 84);
  font-size:12px;
  margin-left:10%;
  padding-top: 5px;
}
#middleRight_2 .middleRight_data_type_2_table_div_2{
  width:80%;
  height:24%;
  //background-color:rgb(5 42 76);
  background-color:rgb(6 48 84/50%);
  font-size:12px;
  margin-left:10%;
  padding-top: 5px;
}
//三排
#middleRight_2 .middleRight_data_type_3_img_1{

  width: 100%;
  height: 100%;
  padding-top: 35%;
  background-image: url(../../dataPanel/img/年度安检.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#middleRight_2 .middleRight_data_type_3_img_2{
  width: 100%;
  height: 100%;
  padding-top: 35%;
  background-image: url(../../dataPanel/img/本月/75.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#middleRight_2 .middleRight_data_type_3_img_3{
  width: 100%;
  height: 100%;
  padding-top: 35%;
  background-image: url(../../dataPanel/img/隐患数.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}

#middleRight_2 .middleRight_data_type_4_img_1{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2%;
  color:#fff;
  background-image: url(../../dataPanel/img/编组 7.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;

}
