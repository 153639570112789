#customerDataPanel{
  width:100vw;height:100vh;
  background-color: #ffffff;
  font-family: 微软雅黑, serif;

}
#customerDataPanel .title_type_1{
  height:100%;
  padding-top:1.5%;
  text-align:center;
  font-size:13px;
  color:rgb(174 188 199);
  float:left;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
#customerDataPanel .title_type_2{
  height:100%;
  padding-top:1.5%;
  padding-left:1.5%;
  text-align:left;
  font-size:13px;
  color:rgb(174 188 199);
  float:left;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}


#customerDataPanel .customerDataPanel_body_img{
  width: 100%;
  height: 100%;
  background-color: rgb(2 15 28);
  //background-image: url(./img/用户端燃气安全驾驶舱切图1.png) !important;
  //background-repeat: no-repeat;
  //background-position: center;
  //background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_ico_1{
  width: 100%;
  height: 100%;
  background-image: url(./img/开@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_ico_2{
  width: 100%;
  height: 100%;
  background-image: url(./img/关@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_top_div{
  width: 100%;
  height: 8%;
  //background-color: rgba(45, 244, 67, 0.5);
}
#customerDataPanel .customerDataPanel_centre_div{
  width: 100%;
  height: 54%;
  //background-color: rgba(255, 11, 180, 0.5);
}
#customerDataPanel .customerDataPanel_bottom_div{
  width: 100%;
  height: 38%;
  //background-color: rgba(42, 108, 255, 0.5);

}
// 顶部模块
#customerDataPanel .customerDataPanel_top_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/head.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_top_img_1_div{
  width: 80%;
  height: 90%;
  background-image: url(./img/重大隐患二维码@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
  background-color: rgba(106, 186, 244, 0.5);
}

// 中部模块
#customerDataPanel .customerDataPanel_centre_left_div{
  width: 27.7%;
  height: 100%;
  padding:  0 0.5% 0.9% 2% ;
  float: left;
  //background-color: rgba(106, 186, 244, 0.5);
}
#customerDataPanel .customerDataPanel_centre_left_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/左上框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_centre_bottom_div{
  width: 44.6%;
  height: 100%;
  float: left;
  padding:  0.5% 0 0.9% 0.1% ;
  //background-color: rgba(244, 82, 106, 0.5);
}
#customerDataPanel .customerDataPanel_centre_bottom_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/监控框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_centre_bottom_right_img_1_div{

  width: 100%;
  height: 100%;
  background-image: url(./img/向上.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto auto   !important;
}
#customerDataPanel .customerDataPanel_centre_bottom_right_img_2_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/圆形.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto auto   !important;
}
#customerDataPanel .customerDataPanel_centre_bottom_right_img_3_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/向下.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto auto   !important;
}

// 单个屏幕
#customerDataPanel .customerDataPanel_centre_bottom_player_body_img_div_3{
  width: 100%;
  height: 98%;
  padding:1.5% 0 0 1.5%;

}
// 上下屏幕
#customerDataPanel .customerDataPanel_centre_bottom_player_body_img_div_4{
  width: 100%;
  height: 49%;
  padding:1.5% 0 0 1.5%;
}
// 4个屏幕
#customerDataPanel .customerDataPanel_centre_bottom_player_body_img_div_5{
  width:50%;
  height:49%;
  float:left;
  padding:1.5% 0 0 1.5%;
  position: inherit;
}

#customerDataPanel .customerDataPanel_centre_bottom_player_body_img_div_1{
  width: 100%;
  height: 100%;
  padding:1.5%
}
#customerDataPanel .customerDataPanel_centre_bottom_player_body_img_div_2{
  width:50%;
  height:49%;
  float:left;padding:1.5% 0 0 1.5%
}

#customerDataPanel .customerDataPanel_centre_bottom_player_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/监控外框@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
;
}


// 中-右
#customerDataPanel .customerDataPanel_centre_right_div{
  width: 27.7%;
  height: 100%;
  float: left;
  padding:  0 2% 0.8% 0.5% ;
  //background-color: rgba(106, 186, 244, 0.5);
}
#customerDataPanel .customerDataPanel_centre_right_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/右上框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
//底部模块
//1
#customerDataPanel .customerDataPanel_bottom_one_div{
  width: 27.7%;
  height: 100%;
  float: left;
  padding:  0 0.7% 1.2% 1.9% ;
  //background-color: rgba(10, 255, 22, 0.5);
}
#customerDataPanel .customerDataPanel_bottom_one_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/右下框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_bottom_one_1_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20%;
  background-image: url(./img/探测器.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_one_2_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20%;
  background-image: url(./img/表具.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_one_3_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20%;
  background-image: url(./img/气瓶.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_one_4_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20%;
  background-image: url(./img/阀门.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_one_5_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20%;
  background-image: url(./img/压力表.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}

//2
#customerDataPanel .customerDataPanel_bottom_two_div{
  width: 22.2%;
  height: 100%;
  float: left;
  padding:  0 0.7% 1.2% 0.5%;
  //background-color: rgba(155, 255, 185, 0.5);
}
#customerDataPanel .customerDataPanel_bottom_two_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/中下框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_bottom_two_1_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/今日自检.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_two_2_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/进气球阀.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_two_3_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/已检查@3x.png) !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto  140% !important;
}
#customerDataPanel .customerDataPanel_bottom_two_4_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/未检定@2x.png) !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto  140% !important;
}
//3
#customerDataPanel .customerDataPanel_bottom_three_div{
  width: 22.4%;
  height: 100%;
  float: left;
  padding:  0 0.8% 1.2% 0.5%;
  //background-color: rgba(62, 125, 255, 0.5);
}
#customerDataPanel .customerDataPanel_bottom_three_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/中下框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_bottom_three_1_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 30%;
  background-image: url(./img/报警次数@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_three_2_img_div{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 30%;
  background-image: url(./img/异常次数@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}

//3
#customerDataPanel .customerDataPanel_bottom_four_div{
  width: 27.7%;
  height: 100%;
  float: left;
  padding:  0 2.1% 1.2% 0.5%;
  //background-color: rgba(106, 186, 244, 0.5);
}
#customerDataPanel .customerDataPanel_bottom_four_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/右下框.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
}
#customerDataPanel .customerDataPanel_bottom_four_1_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/排气扇@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_four_2_img_div{
  width: 100%;
  height: 100%;
  background-image: url(./img/编组 3@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100% !important;
}

#customerDataPanel .customerDataPanel_bottom_five_1_img_div{
  width: 100%;
  height: 90%;
  background-image: url(./img/在线客服@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_five_2_img_div{
  width: 100%;
  height: 90%;
  background-image: url(./img/故障报修@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_five_3_img_div{
  width: 100%;
  height: 90%;
  background-image: url(./img/投诉举报@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#customerDataPanel .customerDataPanel_bottom_five_4_img_div{
  width: 100%;
  height: 90%;
  background-image: url(./img/其他服务@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#customerDataPanel .head_weather_img_1{
  width: 100%;
  height: 100%;
  background-image: url(./img/多云@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;
}
#customerDataPanel .head_weather_img_2{
  width: 100%;
  height: 100%;
  background-image: url(./img/晴@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;

}
#customerDataPanel .head_weather_img_4{
  width: 100%;
  height: 100%;
  background-image: url(./img/雨天.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;
}
#customerDataPanel .head_weather_img_3{
  width: 100%;
  height: 100%;
  background-image: url(./img/温度.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;
}


@keyframes warn1 {
  0% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.0;
  }
  25% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.8;
  }
  75% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 0.0;
  }
}
@keyframes warn2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes warn3 {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 1;
  }
}
/* 产生动画（向外扩散变大）的圆圈 第二个圆 */
#customerDataPanel .pulse1 {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 40%;
  top: 15%;
  //border: 1px solid #F56C6C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  animation: warn1 2s linear 1s infinite;
  box-shadow: 0px 0px 45px 25px #f52822 inset; /* 阴影效果 */

}
#customerDataPanel .pulse2 {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 40%;
  top: 15%;
  //border: 1px solid #F56C6C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  animation: warn1 2s linear 2s infinite;
  box-shadow: 0px 0px 45px 25px #f52822 inset; /* 阴影效果 */
}
#customerDataPanel .pulse3 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  animation: warn2 2s linear 1s infinite;
  box-shadow: 0px 0px 25px 15px #f52822 inset;
  z-index: 9999;
}
#customerDataPanel .pulse4 {
  left: 47.3%;
  top: 29.5%;
  position: absolute;
  background-image: url(./img/警告号.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%  100%   !important;
  width: 4vw;
  height: 7vh;
  animation: warn3 2s linear 1s infinite;
  z-index: 9999;
}
