#deviceModelMap{
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;

}


#deviceModelMap .EditOutlined{
  font-size:15px;
  color:#64a4ff;
  cursor:pointer;
  :hover{
    font-size: 14.3px;
    color: #4f3bff;
    transition: 0.2s;
  }
}

// 中间-左边样式-内部样式
#deviceModelMap .body_left_css_1{
  //cursor:pointer;
  position: absolute;
  z-index: 999;
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  float: left;
  width:400px;                  // 长
  height:700px;               // 宽
  margin-top:4%;              // 离顶
  margin-left: 2%;            // 离左
  border-radius: 3px;         // 圆角
  //border: 1px #cccccc solid;  // 边框
  transition: 0.5s;           // 动画
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
  background-color: rgba(255, 255, 255, 0.9);
}
// 中间-左边样式-内部样式
#deviceModelMap .body_left_button_type_1{
  width:100%;
  height:10%;
  //background-color: #40beff;
  background-color: #eafffd;
  color:#000;
  padding-top:5px;
  text-align: center;
  border-radius: 3px;
  cursor:pointer;
  border: 1px #a0e5ff solid;  // 边框
  :hover{
    font-size: 14px;
  }
}
// 中间-左边样式-内部样式
#deviceModelMap .body_left_button_type_2{
  width:100%;
  height:10%;
  //background-color: #ededed;
  //color: #b7b7b7;
  color: #000;
  padding-top:5px;
  text-align: center;
  border-radius: 3px;
  cursor:pointer;
  border: 1px #e9e9e9 solid;  // 边框
  :hover{
    font-size: 14px;
  }
}
//阀门
#deviceModelMap .body_left_button_img_1{
  width:40px;
  height:35px;


  background-image: url(./img/阀井.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
//调压柜
#deviceModelMap .body_left_button_img_2{
  width:40px;
  height:35px;


  background-image: url(./img/调压柜.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
