#leftType{
  width:100%;
  height: 100%;
}
#leftType .leftType_top_div{
  background-color: #001b2c;
  width: 90%;
  height: 55%;
  margin: 0 5% 0 5%;
  padding: 2% 5% 0 5%;
  border-radius: 2px,
}
