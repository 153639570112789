#dataCenter{
  width: 100%;
  height: 100%;
  //background-color: #ffffff;
}
// 标题
#dataCenter .dataCenter_topSearchBox{
  width: 100%;
  background: #ffffff;
  padding: 30px 30px 1px 30px;
}
// 标题
#dataCenter .dataCenter_topSearchBox h3{
  font-size: 24px;
  font-weight: bold;
  border-left: 10px solid #1F1F1F;
  color: #1F1F1F;
  height: 24px;
  line-height: 24px;
  padding-left: 15px;
  margin-bottom: 20px;

}
// 数据栏 ------------------------------
#dataCenter .dataCenter_css_div_head_1{
  width: 100%;
  height: 150px;
}
// 内框
#dataCenter .dataCenter_css_div_head_1_div_frame{
  width: 25%;
  height: 100%;
  float: left;
  padding: 10px;
}
//数据框内容
#dataCenter .dataCenter_css_div_head_1_div_frame_statistic-card{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  padding: 30px;
}
#dataCenter .dataCenter_css_div_head_1_div_frame_statistic-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  margin-bottom: 12px;
}
#dataCenter .dataCenter_css_div_head_1_div_frame_statistic-value {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 600;
}



// 地图 -------------------------------
#dataCenter .dataCenter_css_div_head_2{
  width: 100%;
  height: 450px;
  padding: 10px;
  margin-top: 5px
}
#dataCenter .dataCenter_css_div_head_2_statistic-card{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
#dataCenter .dataCenter_css_div_head_2_card-header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}
#dataCenter .dataCenter_css_div_head_2_card-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

// 表格 -------------------------------
#dataCenter .dataCenter_css_div_head_3{
  width: 100%;
  height: 500px;
  padding: 10px;
}
#dataCenter .dataCenter_css_div_head_3_statistic-card{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
#dataCenter .dataCenter_css_div_head_3_card-header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}
#dataCenter .dataCenter_css_div_head_3_card-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
