// 今日
#rightType .img_today_25{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/25.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_today_50{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/50.png) !important;
  background-repeat: no-repeat;


}
#rightType .img_today_75{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/75.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_today_100{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/100.png) !important;
  background-repeat: no-repeat;
}
// 本月
#rightType .img_month_25{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/本月/25.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_month_50{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/本月/50.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_month_75{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/本月/75.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_month_100{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/本月/100.png) !important;
  background-repeat: no-repeat;
}
// 今年
#rightType .img_year_25{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/25.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_year_50{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/50.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_year_75{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/75.png) !important;
  background-repeat: no-repeat;
}
#rightType .img_year_100{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(../img/今年/100.png) !important;
  background-repeat: no-repeat;
}
