#addDevicePage{
}
#addDevicePage .body_css{
  background-color: #ffffff;
  width: 100%;
  height:96.5%;
}
#addDevicePage .FormsTwo_1{
  width: 500px;
  height: 420px;
  background-color: #ffffff;
  padding-top:10px ;
}
// 弹窗表单div
#addDevicePage .FormsTwo_2{
  width: 500px;
  height: 360px;
  background-color: #ffffff;
  padding-top:10px ;
}
#addDevicePage .addbutton_css{
  padding-right: 15px;
  float: right;
}
