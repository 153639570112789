#middleLeft1{
  width:100%;
  height:100%;
}

#middleLeft1 .middleLeft_data_1_img_1{
  width: 100%;
  height: 100%;
  background-image: url(../../dataPanel/img/编组 3.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
#middleLeft1 .middleLeft_data_1_img_2{
  width: 16%;
  height: 100%;
  background-image: url(../../dataPanel/img/安全用户.png) !important;
  background-repeat: no-repeat;
  background-size:   85% auto !important;
}
#middleLeft1 .middleLeft_data_1_img_3{
  width: 16%;
  height: 100%;
  background-image: url(../../dataPanel/img/安全隐患.png) !important;
  background-repeat: no-repeat;
  background-size:   80%  auto!important;
}
#middleLeft1 .middleLeft_data_1_button_1{
  width: 45%;
  height: 90%;
  background-color:#002545;
  text-align: center;
  padding-top:1%;
  float:left;
  cursor: pointer;
  border: 1px rgba(255, 255, 255, 0.09) ridge;
}
#middleLeft1 .middleLeft_data_1_button_2{
  width: 45%;
  height: 90%;
  background-color:rgb(35 123 201);
  text-align: center;
  padding-top:1%;
  cursor: pointer;
  float:left;
  border: 1px rgb(89 144 195) ridge;
  border-radius: 2px;
}
