#meterMap{
  background-color: #000e1c;
  width: 100%;
  height: 100%;

}
// 表头------------
#meterMap .head_div{
  background-image: url(./img/3.png) !important;
  background-repeat: no-repeat;
  width: 100%;
  height: 10%;
}
// 主要内容样式------------
#meterMap .body_div{
  //background-color: #7cb305;
  width: 100%;
  height: 90%;
}

// 左框样式------
#meterMap .body_left_div{
  //background-color: #d84a1b;
  width: 23%;
  height: 100%;
  float: left;
}

#meterMap .body_left_top_div {
  background-color: #001b2c;
  width: 90%;
  height: 55%;
  margin: 0 5% 0 5%;
  padding: 2% 5% 0 5%;
  border-radius: 2px;

}
#meterMap .body_left_top_1_div {
  background: linear-gradient(170deg, #012439,#003345 65px,#012439);
  margin-top: 4%;
  padding: 5% 6% 0 6%;
  width: 100%;
  height: 80%;
  border-radius: 5px;
}
#meterMap .body_left_top_1_1_div{
  margin: 4% 0 4% 0;
  background-color: #00488d;
  width: 100%;
  height: 12%;
  border-radius: 15px;
}
#meterMap .body_left_top_1_2_div{
  background: linear-gradient(170deg, #2165b1, #2fbbda);
  height: 100%;
  border-radius: 15px;
}


#meterMap .body_left_bottom_div {
  background-color: #001b2c;
  width: 90%;
  height: 40%;
  margin: 5% 5% 0 5%;
  padding: 5%;
  border-radius: 2px;
}

// 中框样式------
#meterMap .body_middle_div{
  //background-color: #39b3d7;
  width: 54%;
  height: 100%;
  float: left
}
//上层数据
#meterMap .body_middle_top_div{
  //background-color: #7cb305;
  width: 100%;
  height: 5%;
}
//中层数据
#meterMap .body_middle_middle_div{
  background-color: #cccccc;
  width: 100%;
  height: 72%;
}
//低层数据
#meterMap .body_middle_bottom_div{
  width: 100%;
  height: 19%;
  margin-top: 1%;
}
// 右框样式------
#meterMap .body_right_div{
  //body_left_bottom_div
  width: 23%;
  height: 100%;
  float: left;
}
#meterMap .body_right_top_div {
  background-color: #001b2c;
  width: 90%;
  height: 55%;
  margin: 0 5% 0 5%;
  padding: 2% 5% 2% 5%;
  border-radius: 2px;
}
#meterMap .body_right_bottom_div {
  background-color: #001b2c;
  width: 90%;
  height: 40%;
  margin: 5% 5% 0 5%;
  padding: 5%;
  border-radius: 2px;
}
// ----------背景图-----------
// 半圆进度条
#meterMap .img_1 {
  float: left;
  height: 80px;
  width: 46%;
  //top: -100px;
  background-image: url(./img/1.png) !important;
  background-repeat: no-repeat;
}
// 表具信息文本框
#meterMap .img_2 {
  float: left;
  height: 55px;
  width: 40%;
  //top: -100px;
  background-image: url(./img/2.png) !important;
  background-repeat: no-repeat;
}
#meterMap .right_img_1 {
  float: left;
  height: 100%;
  width: 55%;
  //top: -100px;
  background-image: url(./img/购供差.png) !important;
  background-repeat: no-repeat;
}
#meterMap .right_img_2 {
  float: left;
  height: 100%;
  width: 55%;
  //top: -100px;

  background-image: url(./img/供销差.png) !important;
  background-repeat: no-repeat;
}
#meterMap .right_img_3 {
  float: left;
  height: 100%;
  width: 32%;
  //top: -100px;
  background-image: url(./img/字框.png) !important;
  background-repeat: no-repeat;
}
// 今日-25
#meterMap .right_img_bottom_1_1 {
  height: 100%;
  width: 100%;

  background-image: url(./img/今日/25.png) !important;
  background-repeat: no-repeat;
}
// 本月-25
#meterMap .right_img_bottom_2_1 {
  height: 100%;
  width: 100%;

  background-image: url(./img/本月/25.png) !important;
  background-repeat: no-repeat;
}
// 今年-25
#meterMap .right_img_bottom_3_1 {
  height: 100%;
  width: 100%;
  background-image: url(./img/今年/25.png) !important;
  background-repeat: no-repeat;

}

// 检定框
#meterMap .right_img_bottom_4 {
  padding: 2%;
  text-align: center;
  height: 100%;
  width: 88%;
  background-image: url(./img/检定框.png) !important;
  background-repeat: no-repeat;
}
//------半圆进度条-------------
//#meterMap .block {
//  width: 110px;
//  height: 110px;
//  border-left: 15px solid #f6a700;
//  border-top: 15px solid #f6a700;
//  border-right: 15px solid #004354;
//  border-bottom: 15px solid #004354;
//  border-radius: 50%;
//  box-sizing: border-box;
//  position: absolute;
//  margin: 25px 0 0 25.15px;
//  transition: all ease 0.8s;
//}
//
//#meterMap .blockOut {
//  width: 200px;
//  height: 80px;
//
//  position: relative;
//  overflow: hidden;
//}
//
//#meterMap .block.rotate1 {
//  transform: rotate(-45grad);
//}
//
//#meterMap .text {
//  color: #67c4d3;
//  text-align: center;
//  font-size: 18px;
//  top: 70%;
//  position: absolute;
//  width: 80%;
//}
