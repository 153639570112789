#deviceDetails{

}
#deviceDetails .formText_1{
  padding: 5px;
  width: 100%;
  height: 190px;
}
#deviceDetails .formText_1_data{
  width: 1000px;

}
// 分割线
#deviceDetails .formText_1_data_divider{
  width: 99%;
  height: 1px;
  background-color: #999999;
  margin-left: 4px;
}

#deviceDetails .formText_1_installInner ul li{width: 30%; display: inline-block; line-height: 30px; color: #000000;}
#deviceDetails .formText_1_installInner ul li span{color: #666666;}

#deviceDetails .left_class{
  width: 45%;
  float: left;
  height: 30vmax;
}
#deviceDetails .left_class_top{
  width: 100%;
  height: 50%;
}
#deviceDetails .left_class_bottom{
  width: 100%;
  height: 50%;
}
#deviceDetails .left_class_table{
  margin-left: 2.5%;
  width: 95%;
}
//------------------
#deviceDetails .right_class{
  float: right;
  width: 55%;
  height: 32vmax;
}
#deviceDetails .right_class_divider{
  width: 1px;
  height: 98%;
  background-color: #999999;
  float: left;
  margin-top: 4px;

}

#deviceDetails .right_class_Timeline{
  margin-top: 20px;
  margin-left: 50px ;

}
