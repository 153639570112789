#meterMap .body_left_top_div_spen_head{
  font-size: 18px;
  font-weight: bold;
  color: #00b6ec;
}
#meterMap .body_left_top_div_spen_1{
  font-size: 10px;
  color: #fff;
}
#meterMap .body_left_top_div_spen_2{
  color:#00b6ec;
  font-size:18px;
  font-weight: bold;
  margin: 0 5px 0 5px;

}
#meterMap .body_left_top_div_spen_3{
  font-size: 10px;
  color: #fff;
}
#meterMap .body_left_top_div_spen_4{
  font-size: 10px;
  color: #fff;
  float: left;
}
#meterMap .body_left_top_div_spen_5{
  font-size: 10px;
  color: #fff;
  float: right;
}
#meterMap .body_left_top_div_spen_6{
  font-size: 10px;
  font-weight: bold;
  color: #00b6ec;
  float: right;
}
//-----------------
#meterMap .body_left_bottom_div_spen_head{
  font-size: 18px;
  font-weight: bold;
  color: #00b6ec;
}
