#workOrder{
  width: 100%;
  height: 100%;
}
#workOrder .workOrder_topSearchBox{
  width: 100%;
  background: #ffffff;
  padding: 30px 30px 1px 30px;
}
// 标题
#workOrder .workOrder_topSearchBox h3{
  font-size: 24px;
  font-weight: bold;
  border-left: 10px solid #1F1F1F;
  color: #1F1F1F;
  height: 24px;
  line-height: 24px;
  padding-left: 15px;
  margin-bottom: 20px;

}
// 搜索框
#workOrder .workOrder_css_div_head_1{
  width: 100%;
  height: 300px;
  padding: 20px 0 20px 0;

}
// 搜索框-内框
#workOrder .workOrder_css_div_head_1_div_frame{
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  padding: 30px;
}
// 表格
#workOrder .workOrder_css_div_head_2{
  width: 100%;
  //padding: 15px 0 15px 0;
}
#workOrder .workOrder_css_div_head_2_div_frame{
   width: 100%;
   height: 100%;
   background-color: #ffffff;
   border-radius: 2px;
   box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

 }
// 文字
#workOrder .workOrder_css_div_head_2_card-header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
#workOrder .workOrder_css_div_head_2_card-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
