#equipment_frame{
    width: 109%;
    height: 100%;
    .left_frame{
      width: 25%;
      height: 100%;
      float: left;
      margin: 3% 0.5% 2% 1%;
    }
    .particulars_info_div{
      // background-color: aqua;
      width: 60%;
      height: 100%;
      margin: -9% 0.5% 2% 0.5%;
      padding-top: 5%;
      overflow-y: auto;
      overflow-x: hidden;
      border: 2px #d9d9d9 solid;
    }
  }

  #equipment .tree_flow{
    height: 100%;
    width: 100%;
  }

  #equipment_frame .particulars_info_dives{
    width: 160%;
    height: 97%;
    margin: -9% 0.5% 2% 0.5%;
    border: 2px #d9d9d9 solid;
  }

  

  #equipment_frame .left_tt{
    width: 27%;
    height: 75%;
    float: left;
    margin: 3% 0.5% 2% 1%;
  }
  #equipment_frame .left_aa{
    width: 27%;
    height: 75%;
    float: left;
    margin: 3% 0.5% 2% -10%;
  }
  
  #equipment_frame .tree{
    // width: 23%;
    // height: 75%;
    float: left;
    margin: -1% 0.5% 2% 2%;
  }



  #equipment_frame .particulars_div{
    width: 100%;
    height: 103%;
    margin: -5% 0.5% 2% 0.5%;
    border: 1px #d9d9d9 solid;
  }

  #equipment_frame .particulars_account{
    width: 270%;
    height: 96.8%;
    margin: -8.8% 0.5% 2% -40%;
    border: 1px #d9d9d9 solid;
  }

  #equipment_frame .input_div{
    width: 50%;
    height: 50%;
    margin: 1% 0.5% 7% 25%;
    // border: 1px #d9d9d9 solid;
  }

  //----------------------------------
  #equipment_frame .right_frame{
    float: left;
    width: 40%;
    height: 100%;
    margin: 3% 0.5% 2% 0.5%;
  }

  #equipment_frame .boole{
    width: 100%;
    height: 55%;
    margin: 0.5% 0.5% 0.5% 0.5%;
    // overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    border: 0.5px #ffffff solid;
  }

  #equipment_frame .buttons{
    width: 20%;
    height: 10%;
    margin: -7% 0.5% 0.5% 31%;
    // overflow:hidden; //超出的文本隐藏
    // text-overflow:ellipsis; //溢出用省略号显示
    // border: 0.5px #ffffff solid;
  }

  #equipment_frame .button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    border-top: 1px rgb(235; 237; 240) solid;
    padding-top: 1%;
    margin: 20% 0.5% 2% 0.5%;
  }

  #equipment_frame .left_table{
    width: 25%;
    height: 99.5%;
    float: left;
    margin: 3% 0.5% 0.5% 1%;
  }

  #equipment_frame .right_table{
    float: left;
    width: 74%;
    height: 120.5%;
    margin: -2.2%  0% 0.5% -7%;
  }
  
  #equipment_frame .poss{
    float: left;
    width: 99%;
    height: 119%;
    margin: 1% 0.5% 0.5% 0.5%;
  }

  #equipment_frame .poes{
    float: left;
    width: 99%;
    height: 119%;
    margin: 0.5% 0.5% 0.5% 0%;
  }
  #equipment_frame .buttoner{
    // float: left;
    // width: 99%;
    // height: 119%;
    margin: -3% 2% 0.5% 3%;
  }


  #equipment_frame .nTable_info_div{
    width: 122%;
    height: 97%;
    margin: -5.5% 0.5% 4% 38%;
    // overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    border: 1px #d9d9d9 solid;
  }
  #equipment_frame .aTable_info_div{
    width: 100%;
    height: 80%;
    margin: 4% 0.5% 2% -3%;
    // overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    border: 1px #d9d9d9 solid;
  }

  #equipment_frame .top_button{
    flex: none;
    margin: 0.5% 10% 0.5% 14%;
  }

  
  //--------------------------------------
  #equipment_frame .title{
    display:block;
    width:80px;
    height:30px;
    position:relative;
    top:-15px;
    left: 20px;
    text-align: center;
    background: white;
  }
  
  #equipment_frame .clickRowStyl{
    background-color: #cccccc}
  
  //修改表头文字、背景颜色
  #equipment_frame .ant-table-thead > tr >th{
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-size: 15px;
  }
  
  //修改列表文字、背景颜色
  
  //在鼠标悬浮时背景色展示在当前项非当前行
  // #equipment_frame .ant-table-tbody > tr > td {
  //   background: rgba(255,255,255,0) !important;
  // }
  // #equipment_frame .ant-table-tbody > tr >td:hover{
  //   background: rgb(255,255,255) !important;
  // }
  // //去除鼠标悬浮的背景色
  // #equipment_frame .ant-table-tbody > tr > td {
  //   background: rgba(255,255,255,0) !important;
  // }
  // #equipment_frame .ant-table-tbody > tr >td:hover{
  //   background: rgba(255,255,255,0) !important;
  // }
  