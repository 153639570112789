.column-left {
    height: 93.3vh;
    background-color: #fff;
    position: relative;
    float: left;
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right: none;
    }
}
.column-right {
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
}
.resize-save {
    position: absolute;
    top: 0; right: 5px; bottom: 0; left: 0;
    overflow-x: hidden;
}
.resize-bar {
    width: 15vw; height: inherit;
    min-width: 15vw;
    max-width: 30vw;
    resize: horizontal;
    cursor: ew-resize;
    opacity: 0;
    overflow: scroll;
}
/* 拖拽线 */
.resize-line {
    position: absolute;
    right: 0; top: 0; bottom: 0;
    // border-right: 2px solid #eee;
    // border-left: 1px solid #fff;
    pointer-events: none;
}
.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
    border-left: 1px soild #ffffff;
}
.resize-bar::-webkit-scrollbar {
    width: 15vw; height: inherit;
}
// 客户对话按钮
#homeDialogButton{
    width:50px;
    height:35px;
    position: absolute;
    //overflow: hidden;
    right:0;
    z-index:999;
    bottom: 10px;

}
// 客服图标div
#homeDialogButton .homeDialogButton_div_1{
    transition: 0.5s;
    float: right;
    width:40px;
    height:95%;
    border-radius:  15px 0 0 15px;
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2); // 底阴
}
// 客服图标
#homeDialogButton .homeDialogButton_div_img{
    cursor:pointer;
    float: left;
    width: 95%;
    height: 95%;
    background-image: url(./homeImg/customerService.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80% !important;
}
// 客服图标
#homeDialogButton .homeDialogButton_div_img_1{
    position: absolute;
    cursor:pointer;
    float: left;
    width: 45%;
    height: 45%;
    background-image: url(./homeImg/warn.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80% !important;
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
// 客服图标悬浮效果
#homeDialogButton .homeDialogButton_div_1:hover{
    color: #5d7efa;
    width:95%;
    height: 100%;
    transition: 0.5s;
}
// 客服对话框
#homeDialogBox {
    border-radius:  10px;
    width:50%;
    height:600px;
    position: absolute;
    background-color: #ffffff;
    z-index:9999;
    right:25%;
    bottom: 20%;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2); // 底阴
}

// 客服对话框
// 对话框标题样式
#homeDialogBox .homeDialogBox_top_div{
    border-radius:  5px 5px 0 0;
    background-color: #1890ff;
    width: 100%;
    height: 5%;
    border-bottom: 1px #000000;
    overflow: hidden;
}
#homeDialogBox .homeDialogBox_top_div_1{
    padding-top: 5px;
    text-align: center;
    width: 10%;
    height: 100%;
    float: left;
}
#homeDialogBox .homeDialogBox_top_div_2{
    padding-top: 5px;
    width: 90%;
    height: 100%;
    float: left;

}
// 对话框-主体
#homeDialogBox .homeDialogBox_body_div{
    border-radius: 0 0  5px 5px;
    width: 100%;
    height: 95%;
    overflow: hidden;
    background-color: #eeeeee;
    padding: 5px;
}
// 对话框-主体-左
#homeDialogBox .homeDialogBox_body_div_left{
    float: left;
    width: 23%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    border-right: 2px #eeeeee solid;
}

// 头像
#homeDialogBox .homeDialogBox_body_div_left_img_1{

    float: left;
    width: 100%;
    height: 100%;
    background-image: url(./homeImg/profilePicture-1.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80% !important;
}
#homeDialogBox .homeDialogBox_body_div_left_img_2{

    float: left;
    width: 100%;
    height: 100%;
    background-image: url(./homeImg/profilePicture.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80% !important;
}



// 对话框-主体-右
#homeDialogBox .homeDialogBox_body_div_right{
    float: left;
    width: 77%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    padding: 5px;
}
// 对话框-主体-右-上部分展示对话内容框
#homeDialogBox .homeDialogBox_body_div_right_top_div{
    width: 100%;
    height: 70%;
    overflow: hidden;
    background-color: #fff;
}
// 对话框-主体-右-上部分展示对话内容框
#homeDialogBox .homeDialogBox_body_div_right_bottom_div{
    width: 100%;
    height: 29%;
    background-color: #fff;

}
//// 对话框-主体-右-上部分展示对话内容框
//#homeAmplify .amplify_div{
//    padding-top: 7px;
//    position: absolute;
//    transition: 0.5s;
//    float: left;
//    top:40%;
//    width:18px;
//    height:40px;
//    border-radius: 0 15px 15px 0  ;
//    background-color: #fff;
//    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2); // 底阴
//    z-index: 99999;
//
//}
//#homeAmplify .amplify_div:hover{
//    top:39.8%;
//    padding-top: 8px;
//    width:20px;
//    height:42px;
//    transition: 0.5s;
//    cursor:pointer;
//}
