// 巡检内容配置
#contentConfiguration{

}
#contentConfiguration .table_css{
  background-color: #eeeeee;

}
#contentConfiguration .table_font_css{
  font-weight: bold;
  font-size: 18vw;
  float: left;
}




#execute{

}

