body {
    height: 100vh;
    width: 100%;
    background-image: url(../images/index-bg.png) !important;
    /* background-repeat: no-repeat !important; */
    background-size: 100% 100% !important;
    position: relative !important;
    font-family: PingFang SC-Medium, PingFang SC;
    /* overflow:hidden; */
}

.regional-content {
    background: none !important;


}

.background-application {
    text-align: right;
    margin-right: 80px;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1em !important;
}

header {
    height: 60px;
    background: url(../images/head-bg.png) no-repeat;
    background-size: 100% 100%;
}

header .head-title {
    font-size: 19px;
    color: rgba(119, 234, 243);
    height: 48px;
    line-height: 48px;
    text-align: center;
    text-shadow: 0px 1px 4px rgba(119, 234, 243, 0.5);
}

.content-box {
    padding-left: 2px;
    display: flex;
}

.content-box .side-menu {
    width: 196px;
    background: url(../images/menu-bg.png) no-repeat;
    background-size: 100% 98.50%;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 832px; */
    height: 883px;
    /* overflow: hidden;
    overflow-y: scroll; */
}

.side-menu::-webkit-scrollbar {
    display: none;
}

.side-menu .menu-item>div {
    padding-left: 10px;
}

.side-menu .menu-item-list .menu-item {
    position: relative;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: rgba(255, 255, 255, 0.85);
    line-height: 16px;
    line-height: 40px;
    background: rgba(255, 255, 255, 0.03);
    /* border-top: 1px solid rgba(26, 76, 116, 0.5); */
}

.side-menu .menu-item-list .menu-item:not(:last-child):after {
    position: absolute;
    content: "";
    width: calc(100% - 20px);
    height: 1px;
    border-top: 1px solid rgba(26, 76, 116, 0.5);
    left: 50%;
    transform: translateX(-50%);
}

.sub-menu-list,
.menu-item .icon-minus,
.all-area .icon-plus {
    display: none;
}

.all-area .icon-minus {
    display: inline-block;
}

.sub-menu-list .sub-menu-item {
    text-indent: 30px;
}

.sub-menu-list li.sub-selected {
    color: #78F4FD;
    /* display: none; */
}

.menu-item-list .menu-item.selected>div {
    color: #78F4FD;
    background: url(../images/menu-select.png) no-repeat;
    background-size: 100% 100%;
}



.content-main {
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
}

.content-main .main-title {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.85);
    font-family: PingFang SC-Medium, PingFang SC;
    line-height: 28px;
    margin-bottom: 24px;
}

.content-main .data-list {
    display: flex;
}

.content-main .data-list li {
    color: rgba(255, 255, 255, 0.65);
    font-size: 12px;
    flex: 1;
    border-left: solid 1px rgba(255, 255, 255, 0.15);
    padding-left: 15px;
}

.content-main .data-list li:first-child,
.content-main .data-list li:last-child {
    border-left: 0;
}

.data-list .num {
    font-size: 30px;
    font-family: Helvetica-Regular, Helvetica;
    color: #78F4FD;
}

.data-list li p:first-child {
    margin-bottom: 8px;
}

.data-list {
    margin-bottom: 24px;
}

.chart-box {
    display: flex;
}

.chart-box .chart-item {
    flex: 1;
    display: flex;
}

.chart-item .three-box {
    flex: 2;
    margin-right: 16px;

}

.chart-item .two-box {
    flex: 1;
    margin-right: 16px;
}


.chart-list {
    display: flex;
}

.chart-item .chart-list>li {
    margin-right: 16px;
    flex: 1;
    height: 350px;
    /* height: 304px; */
    background: url(../images/chart-item-bg.png) no-repeat;
    background-size: 100% 100%;
    /* margin-bottom: 16px; */
}

.three-box .special-box {
    width: 100%;
    height: 350px;
    /* height: 304px; */
    background: url(../images/chart-item-bg.png) no-repeat;
    background-size: 100% 100%;
}

.cancel-space {
    margin-right: 0 !important;
}

.two-box .chart-item-box {
    width: 100%;
    height: 348px;
    /* height: 304px; */
    background: url(../images/chart-item-bg.png) no-repeat;
    background-size: 100% 100%;
}

.two-box .chart-item-box:first-child {
    margin-bottom: 16px;
}

.two-box .circle-dot {
    position: relative;
}

.two-box .circle-dot:before {
    content: "";
    width: 4px;
    height: 4px;
    opacity: 1;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
}

.two-box .red-tr .circle-dot:before {
    background: #F53B38;
}

.two-box .yellow-tr .circle-dot:before {
    background: #E8CE65;
}

/* .two-box.red-alarmTr.circle-dot:first-child {
    background: #F53B38;
} */

.two-box .red-alarmTr:nth-child(2){
    color: #F53B38;
}
.two-box .yellow-alarmTr:nth-child(3){
    color: #E8CE65;
}

.two-box .table-wrap {
    padding: 0 7px 0 7px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 262px;
}

.two-box table {
    width: 100%;
    border-collapse: collapse;
}

.two-box table td,
.two-box table th {
    border: 1px solid rgba(49, 162, 255, 0.4);
    font-size: 12px;
    height: 36px;
    font-family: PingFang SC-Regular, PingFang SC;
}

.two-box table tr td:first-child,
.two-box table tr th:first-child {
    padding-left: 16px;
}

.two-box table th {
    color: rgba(120, 244, 253, 1);
}

.two-box table td {
    color: rgba(255, 255, 255, 0.65);
}

.two-box .red-tr td {
    color: #78F4FD;
}

.two-box .yellow-tr td {
    color: #E8CE65;
}


.content-box .treeColor {
    background: rgb(0 0 0 / 5%);
    color: #78F4FD !important;
    /* height: 100%; */
}




.chart-item-box>p,
.rank-list>p,
.special-box>p {
    line-height: 22px;
    padding-left: 16px;
    padding-top: 16px;
    margin-bottom: 12px;
}

.rank-list .title-dot,
.chart-item-box .title-dot,
.special-box .title-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 3px;
    background: #F96927;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    /* Internet Explorer */
    -moz-transform: rotate(45deg);
    /* Firefox */
    -webkit-transform: rotate(45deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(45deg);
    /* Opera */
}

.rank-list .title-txt,
.chart-item-box .title-txt,
.special-box .title-txt {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #78F4FD;
}

.chart-item-box .chart-list {
    padding: 0 16px 0 16px;
    display: flex;
}

.chart-list>ul:first-child {
    margin-right: 8px;
}

.chart-list>ul {
    flex: 1;
}

.chart-list>ul li {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46%;
    height: 38px;
    background: url(../images/down-item-bg.png) no-repeat;
    background-size: 100% 100%;
    color: rgba(255, 255, 255, 0.85);
    font-size: 12px;
    text-align: center;
    margin-bottom: 11px;
    margin-left: 14px;
    float: left;
}

/* .chart-list>ul li.active {
    background: url(../images/down-active-bg.png) no-repeat;
    background-size: 100% 100%;
    color: #F96927;
} */
.chart-list>ul li.active:first-child{
    background: url(../images/down-active-bg.png) no-repeat;
    background-size: 100% 100%;
    /* color: #F96927; */
}

.rank-list {
    width: 302px;
    background: url(../images/chart-item-bg.png) no-repeat;
    background-size: 100% 98%;
}

.rank-list .top-rank-list {
    padding: 0 16px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 580px; */
    height: 627px;
}

.rank-list .top-rank-list li {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: rgba(255, 255, 255, 0.45);
    line-height: 30px;
    padding-bottom: 24px;
    /* overflow-y: auto;
    overflow-x: hidden; */
}

.rank-list .progress-box {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
}

.rank-list .progress-bar {
    height: 4px;
    background: linear-gradient(270deg, #78F4FD 0%, rgba(24, 133, 255, 0) 100%);
}

.top-rank-list p>span:first-child {
    color: #ffffff;
    margin-right: 8px;
}

.use-num {
    color: #ffffff;
    font-size: 14px;
    margin-left: 12px;
}

.chart-legend {
    padding-left: 10px;
    padding-top: 30px;
    /* flex: 1; */
}

/* .three-box .chart-legend{
    float: left;
} */
.chart-legend .uninstall-box {
    margin-bottom: 24px;
}

.chart-legend .uninstall-box>p,
.chart-legend .install-box>p {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: rgba(255, 255, 255, 0.65);
}

.chart-legend .uninstall-box>p:first-child,
.chart-legend .install-box>p:first-child {
    margin-bottom: 10px;
}

.chart-legend .uninstall-num {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #78F4FD;
}

.chart-legend .install-num {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #78F4FD;
}

.chart-legend .uninstall-cell img {
    width: initial;
}

/* .chart-legend .install-cell{
    background: url(../images/in-null-bg.png) no-repeat;
} */
.chart-legend .cell-null img {
    width: initial;
}

.chart-legend .cell-null {
    overflow: hidden;
    position: absolute;
    top: 0;
}

.circle-chart-wrap {
    display: flex;
}

.chart-legend .cell-box {
    width: fit-content;
    position: relative;
}

/* .chart-legend .cell-box{
    height: 10px;
    width: 150px;
    border-left: 1px solid #78F4FD;
    border-right: 1px solid #78F4FD;
    padding: 0 4px 0 4px;
}
.cell-box .cell-null{
    box-sizing: border-box;
    height: 10px;
    border: 1px solid #78F4FD;
    -moz-box-shadow: 0 0 8px #78F4FD;
    box-shadow: 0 0 8px #78F4FD;
} */
.chart-legend .uninstall-num {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    line-height: 30px;
    color: #78F4FD;
}

.chart-legend .install-num {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    line-height: 30px;
    color: #1D8BFF;
}

#circle-chart {
    flex: 1;
}

#bar-chart {
    width: 100%;
    height: 80%;
}

#line-chart {
    width: 100%;
    height: 80%;
}

.clear-float:after {
    content: '';
    height: 0;
    line-height: 0;
    display: block;
    visibility: hidden;
    clear: both;
}

/* 适配屏幕宽度大于1920px的 */
@media screen and (min-width: 1920px) {
    .content-box {
        padding-top: 2%;
    }

    dl, ol, ul {
        margin-top: 0;
        margin-bottom: 1em !important;
    }

    .data-list {
        margin-bottom: 24px !important;
    }

    .chart-item .chart-list>li,
    .three-box .special-box,
    .two-box .chart-item-box {
        /* height: 320px; */
        height: 351px;
    }

    .two-box table tr td:not(:first-child),
    .two-box table tr th:not(:first-child) {
        padding-left: 16px;
    }
}

/* 适配屏幕宽度小于1440px的 */
@media screen and (max-width: 1440px) {
    body {
        background-size: 100% 107% !important;
    }

    .data-list {
        margin-bottom: 24px !important;
    }

    .content-main .main-title {
        margin-bottom: 5px;

    }

    .data-list {
        margin-bottom: 10px;
    }

    .content-main .main-title {
        font-size: 18px;
        line-height: 22px;
    }

    .data-list .num {
        font-size: 20px;
    }

    .rank-list {
        width: 280px;
    }

    .chart-item .chart-list>li,
    .chart-item .three-box,
    .chart-item .two-box {
        margin-right: 10px;
    }

    .content-main {
        padding-left: 10px;
        padding-right: 10px;
    }

    .chart-item .chart-list>li,
    .two-box .chart-item-box:first-child {
        margin-bottom: 10px;
    }

    .chart-item .chart-list>li,
    .three-box .special-box,
    .two-box .chart-item-box {
        height: 280px;
    }

    .three-box .special-box {
        background-size: 100% 100%;
    }

    .two-box table td,
    .two-box table th {
        padding-left: 5px;
    }

    ol,ul,dl {
        /* margin-top: 0; */
        margin-bottom: 0em !important;
    }

    .two-box .table-wrap {
        padding: 0 4px 0 4px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 80%;
    }

    .two-box table tr td:not(:first-child),
    .two-box table tr th:not(:first-child) {
        padding-left: 5px;
    }

    .rank-list {
        background-size: 100% 99%;
    }


    .chart-list>ul li {
        margin-bottom: 12px;
        margin-left: 9px;
    }

    .rank-list .top-rank-list {
        height: 525px;
    }
    .chart-legend {
        padding-left: 10px;
        padding-top: 0px;
        /* flex: 1; */
    }

}


/* 滚动条 */
.qq::-webkit-scrollbar {
    height: 5px;
    width: 0px;
}

.qq::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    cursor: move;
}

.qq::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(8, 156, 224, 0.1);
}
