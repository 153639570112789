.body_div{  // 整体大小设置
  width: 100%;
  height: 100%;
  //border: 1px #d9d9d9 solid;
}
.left_body_title_div{
  display:block;
  width:80px;
  position:relative;
  margin-top: -10px;
  left: 20px;
  text-align: center;
  background: white;
}
.left_body_div{ // 左区域大小设置
  width: 58%;
  height: 98%;
  margin: 0.5%;
  float: left;
  //border: 1px #d9d9d9 solid;
}
.left_body_top_div{ // 左区域上层部位大小设置
  width: 100%;
  height: 15%;
  border: 1px #d9d9d9 solid;
}
.left_body_top_title_div{
  margin-top: 3%;
  margin-left: 3%;
  width: 80%;

}
.left_body_centre_div{  // 左区域中层部位大小设置
  width: 100%;
  height: 48%;
  margin-top: 1.5%;
  border: 1px #d9d9d9 solid;
}
.left_body_centre_top_title_div{
  width: 70%;
  font-size: 15px;
  margin: 2% 25%;

}
.left_body_centre_title_div{
  width: 60%;
  height: 60%;
  float: left;
  margin: 2% 0.5% 2% 15%;

}
.left_body_below_div{  // 左区域下层部位大小设置
  width: 100%;
  height: 33.5%;
  margin-top: 1.5%;
  border: 1px #d9d9d9 solid;
}
.left_body_below_title_div{
  width: 800px;
  height: 55%;
  margin: 1% 0 0.5% 5%;
  font-size: 15px;

}


.right_body_div{// 右区域大小设置
  width: 40%;
  height: 98%;
  margin: 0.5%;
  float: left;
  //border: 1px #d9d9d9 solid;
}
.right_body_top_div{// 右区域大小设置
  width: 100%;
  height: 20%;
  float: left;

}
.right_body_top_titile_div{ // 右框上半部分titile内容

  margin: 5% -2% 0 15%;
  float: left;
}
.right_body_top_botton_div{ // 右框上半部分按钮内容
  margin: 5% ;
  float: left;
}
.right_body_table_div{// 右区域大小设置
  width: 100%;
  height: 80%;
  float: left;
  //border: 1px #d9d9d9 solid;
}
.right_body_table_head_div{
  width: 100%;
  height: 10%;
  background-color: #fafafa;
  border-right: 1px  rgb(240 240 240) solid;
  border-left: 1px  rgb(240 240 240) solid;
  border-top: 1px  rgb(240 240 240) solid;
}
.right_body_table_head_titile_div{
  padding: 1%;
}
.right_body_table_data_div{
  width: 100%;
  height: 90%;

}
