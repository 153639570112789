#RiskMap{

}
// 标头



// 弹窗框架-主框-展开-1
#RiskMap .drawer_types_1_1{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  float: left;
  z-index: 999;
  position: absolute;
  width:20%;                 // 长
  height:550px;               // 宽
  margin-top:5%;             // 离顶
  border-radius: 3px;         // 圆角
  border: 1px #cccccc solid;  // 边框
  transition: 0.5s;           // 动画
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 弹窗框架-主框-收缩-2
#RiskMap .drawer_types_1_2{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  float: left;
  z-index: 999;
  position: absolute;
  transition: 0.5s;         // 动画
  width:30px;               // 长
  height:70px;              // 宽
  margin-top:18%;           // 离顶
  border-radius: 3px;       // 圆角
  border: 1px #cccccc solid;// 边框
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03);// 底阴

}
// 弹窗框架-伸缩按钮-1
#RiskMap .drawerButton_1_1{
  padding-left: 2%;
  padding-top:70%;
  width:10%;
  height:100%;
  float:right;
  border-radius:  0 3px 3px 0;
}
// 弹窗框架-伸缩按钮-2
#RiskMap .drawerButton_1_2{
  transition: 0.5s;         // 动画
  padding-left: 10%;
  padding-top:80%;
  width:100%;
  height:100%;
  float:right;
  border-radius:  0 3px 3px 0;
}
// 弹窗框架-内容框架
#RiskMap .drawerData_1_1{
  padding: 20px 10px 20px 10px;
  width:90%;
  height:100%;
  float:left;
}

// 左框-图片
#RiskMap .drawerDataImg25{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(./img/leftType/25.png) !important;
  background-repeat: no-repeat;
}
#RiskMap .drawerDataImg50{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(./img/leftType/50.png) !important;
  background-repeat: no-repeat;
}
#RiskMap .drawerDataImg75{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(./img/leftType/75.png) !important;
  background-repeat: no-repeat;
}
#RiskMap .drawerDataImg100{
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url(./img/leftType/100.png) !important;
  background-repeat: no-repeat;
}
//-----------------------右------------------------------
#RiskMap .drawer_types_2_1{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  z-index: 999;
  position: absolute;
  width:20%;                 // 长
  height:550px;               // 宽
  margin-top:5%;             // 离顶
  margin-left: 80%;
  border-radius: 3px;       // 圆角
  border: 1px #cccccc solid;// 边框
  transition: 0.5s;         // 动画
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
#RiskMap .drawer_types_2_2{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  z-index: 999;
  position: absolute;
  transition: 0.5s;         // 动画
  width:30px;               // 长
  height:70px;              // 宽
  margin-top:18%;             // 离顶
  margin-left: 98.5%;
  border-radius: 3px;       // 圆角
  border: 1px #cccccc solid;// 边框
  //background-color: #ffffff;
  //opacity:0.5;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03);// 底阴
}
// 弹窗框架-伸缩按钮-1
#RiskMap .drawerButton_2_1{
  padding-left: 2%;
  padding-top:70%;
  width:10%;
  height:100%;
  float:left;
  border-radius:  0 3px 3px 0;
}
// 弹窗框架-伸缩按钮-2
#RiskMap .drawerButton_2_2{
  transition: 0.5s;         // 动画
  padding-left: 10%;
  padding-top:80%;
  width:100%;
  height:100%;
  float:left;
  border-radius:  0 3px 3px 0;
}
// 弹窗框架-内容框架
#RiskMap .drawerData_2_1{
  padding: 20px 10px 20px 10px;
  width:90%;
  height:100%;
  float:left;
}
// ----------------------中--------------------------
#RiskMap .drawer_types_3_1{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  z-index: 999;
  position: absolute;
  width:60%;                // 长
  height:320px;             // 宽
  margin-top:33.8%;         // 离顶
  margin-left: 20%;
  border-radius: 3px;       // 圆角
  border: 1px #cccccc solid;// 边框
  transition: 0.5s;         // 动画

  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
#RiskMap .drawer_types_3_2{
  overflow: hidden;
  text-Overflow: ellipsis;
  white-Space: nowrap;
  z-index: 999;
  position: absolute;
  transition: 0.5s;         // 动画
  width:70px;               // 长
  height:30px;              // 宽
  margin-top:49%;           // 离顶
  margin-left: 48.5%;
  border-radius: 3px;       // 圆角
  border: 1px #cccccc solid;// 边框
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03);// 底阴
}


// 弹窗框架-伸缩按钮-1
#RiskMap .drawerButton_3_1{
  position: absolute;
  text-align: center;
  width:100%;
  height:10%;
  border-radius:  0 3px 3px 0;
}
// 弹窗框架-伸缩按钮-2
#RiskMap .drawerButton_3_2{
  position: absolute;
  transition: 0.5s;         // 动画
  text-align: center;
  width:100%;
  height:85%;
  border-radius:  0 3px 3px 0;
}
#RiskMap .drawerData_3_1{
  margin-top: 2%;
  position: absolute;
  width:100%;
  height:95%;
}
