
.button_div{
  float: left;
}

.leftTableData_div{
  width: 49.5%;
  height: 80.5%;
  margin: 0.5%;
  float: left;
  background-color: #ffffff;
}
.leftTableData_button_div{
  padding-top: 0.5%;
  padding-bottom: 0.7%;
}
.leftTableData_Top_div1{
  width: 99.9%;
  height: 30px;
  background-color: #fafafa;
  border-right: 1px  rgb(240 240 240) solid;
  border-left: 1px  rgb(240 240 240) solid;
  border-top: 1px  rgb(240 240 240) solid;

}
.leftTableData_Title_div{
  padding-top: 1px;
  padding-left: 5px;
}
//----------------------------
.rightTableData_div{
  width: 49%;
  height: 50.5%;
  margin: 0.5% 0 0.5% 0;
  float: left;
  background-color: #ffffff;
}
.table-color-dust {
  background-color: #999999;
}
.table-color-dust:hover {
  background-color: #999999;
}
.table-color-dust:hover >.ant-table-cell{
  color: #f5222d;

}
.table-color-dust>.ant-table-cell {
  color: #f0f0f0;
}

.rightTableData_button_div_sx{
  margin-top: 5px;
  margin-bottom: 5px;
}
//.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
//  background-color: #f5222d!important;
//}

.deliver_from_text {
  .ant-form-item{
    margin-bottom: 0px;
    margin: 1px 0;

  }
  .ant-divider > .ant-divider-horizontal > .ant-divider-with-text{
    margin: 5px 0;
  }
}
