#baiduMap{

};
#baiduMap .mapCssTypeOne{
  width: 100%;
  height: 100%;
  z-index:9998;
  position: absolute;
  left:0;
  top:0;
};
#baiduMap .mapCssTypeTwo{
  width: 100%;
  height: 92%;
  z-index:9998;
};

// 开
#baiduMap .mapSwithTypeOne{
  z-index:9999;
  position: absolute;
  width: 33px;
  height: 33px;
  color:#fff;
  text-align: center;
  background:#000;
  opacity: 0.7;
  font-weight: bold;
  font-size: 20px;
  top:0;
  right:0;
  text-decoration: none;
  cursor: pointer;
  border: 1px  #ffffff solid;
};

// 关
#baiduMap .mapSwithTypeTwo{
  z-index:9999;
  position: absolute;
  width: 33px;
  height: 33px;
  color:#fff;
  text-align: center;
  background:#000;
  opacity: 0.7;
  font-weight: bold;
  font-size: 20px;
  right: 23%;
  text-decoration: none;
  cursor: pointer;
  border: 1px  #ffffff solid;
};
#baiduMap .mapSwithTypeOne :hover{
  height: 35px;
  font-size: 22px;
}
#baiduMap .mapSwithTypeTwo :hover{
  height: 35px;
  font-size: 22px;
}



#baiduMap .SpinSwithTypeOne{
  width: 100%;
  height: 5%;
  color:#fff;
  text-align: center;
  position: fixed;
  background: #000;
  opacity: 0.7;
  z-index: 9999;
  font-weight: bold;
  top: 0;
  left: 0;
};
#baiduMap .SpinSwithTypeTwo{
  width: 54%;
  height: 5%;
  color:#fff;
  text-align: center;
  position: fixed;
  background: #000;
  opacity: 0.7;
  z-index: 9999;
  font-weight: bold;

}
