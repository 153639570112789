#middleLeft{
  width:100%;
  height:100%;
}

#middleLeft .middleLeft_data_1_img_1{
  width: 100%;
  height: 100%;
  background-image: url(../../dataPanel/img/编组 3.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
#middleLeft .middleLeft_data_1_img_2{
  width: 16%;
  height: 100%;
  background-image: url(../../dataPanel/img/安全用户.png) !important;
  background-repeat: no-repeat;
  background-size:   85% auto !important;
}
#middleLeft .middleLeft_data_1_img_3{
  width: 16%;
  height: 100%;
  background-image: url(../../dataPanel/img/安全隐患.png) !important;
  background-repeat: no-repeat;
  background-size:   85%  auto!important;
}
