.card_open_account{
  //width: 55.8%;// 旧
  width: 99.8%;
  height: 100%;
  float: left;

}
.card_open_account_div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-top: 1px rgb(235, 237, 240) solid;
  padding-top: 1%;
}

.card_relation_chart{
  width: 44%;
  height: 100%;
  float: right;

}
.openformText ul li{width: 50%; display: inline-block; line-height: 30px;}
//.openformText ul li span{color: #666666;}
//.openformText ul li Input{width: 50%}
