#addEquipmenet{

}

.ant-select{
  color: black  !important;
}


.left_tree_div{

  float: left;
  width: 18%;
  height: 100vmax;
  border-right: 5px  rgb(240 240 240) solid;
}
.left_tree_div_input{
  margin-top: 5%;
  margin-left: 10%;
}
.left_tree_div_tree_div{
  width: 90%;
  height: 38vmax;
  padding-left: 10%;
  margin-top: 15px;
  overflow-y: auto;
}
.left_tree_div_tree_data_div{
  margin: 5%;
  border-bottom: 1px  #d9d9d9 solid !important;
  //position: relative;
}
.site-tree-search-value {
  color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
  color: #d84a1b;
}
.left_tree_div_tree_data_div  .ant-tree-treenode{
  height: 90%!important;
  border-top: 1px  #d9d9d9 solid !important;
  border-right: 1px  #d9d9d9 solid !important;
  border-left: 1px  #d9d9d9 solid !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before{
  height: 100%!important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before{
  height: 100%!important;
}


.right_head_div{
  float: left;
  width: 82%;
  height: 100%;

}
.right_head_input_div{
  width: 98%;
  padding: 1%;
}
.right_head_collapse_div{
  overflow-y: auto;
  width: 100%;
  height: 37%;
  border-top: 1px  #d9d9d9 solid !important;
}

.right_head_collapse_Descriptions_3_div{
  float: left;
  width: 310px!important;
  height: 90px;
  border: 1px  #d9d9d9 solid !important;
  padding: 15px 0 0 25px;
}
.right_head_collapse_Descriptions_3_div:hover{
  background-color: #39b3d7;
  .ant-typography{
    color: rgba(255, 255, 255, 0.85)
  }
  .right_head_collapse_Descriptions_3_input_div{
    color: black;
  }
}



.drawer_Checkbox_div{   // 右侧弹出框，复选框内容
  background-color: #dce0e6;
  float: left;
  margin: 5px;
  padding: 2% 5% 0 1%;
  //display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //display: inline-block;
  width: 22%;
  height: 40px;
  border: 1px  #d9d9d9 solid !important;
}
.drawer_Checkbox_div_span{  // 右侧弹出框，复选框内容
  padding-top: 1.5%;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 70%;
}

.right_head_collapse_Button_div{
  padding: 2%;
  width: 100%;
  height: 100px;

}


::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
  background-color: #dce0e6;
}
::-webkit-scrollbar {//滚动条的宽度
  width:3px;
  height:9px;
}
::-webkit-scrollbar-thumb {//滚动条的设置
  background-color:#666666;
  background-clip:padding-box;
  min-height:28px;
}
::-webkit-scrollbar-thumb:hover {
  background-color:#bbb;
}
