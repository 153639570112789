.font_size09{
    font-size: 0.9rem;
}
.font_size0{
    font-size: 1.0rem;
}
.font_size1{
    font-size: 1.1rem;
}
.font_size2{
    font-size: 1.2rem;
}
.font_size3{
    font-size: 1.3rem;
}
.font_size4{
    font-size: 1.4rem;
}
.font_size5{
    font-size: 1.5rem;
}
.font_size6{
    font-size: 1.6rem;
}
.font_size7{
    font-size: 1.7rem;
}
.font_size8{
    font-size: 1.8rem;
}
.font_size9{
    font-size: 1.9rem;
}
.font_size10{
    font-size: 2rem;
}

.item_body_number{
    height: 100%;
    width: 100%;
    overflow: auto;
}
.item_body_screen{
    height: 100vh;
    width: 100vw
}
.item_body_number_back{
    background-color: #f0f2f5;
    overflow-x: hidden;
    overflow-y: auto;
    .item_body_number()
}
.div_from{
    background-color: #ffffff;
    padding: 1%;
}
.div_title_from{
    margin-top: 0.5%;
    .div_from()
}

.div_back_arrow{
    height: 4vh;
    line-height: 4vh;
    font-weight: 600;
    padding-left: 1vw;
    background-color: #f0f2f5
}
.back_arrow{
    cursor: pointer;
    .font_size2();
    :hover {
        color: #78dfe9
    }
}
.table_title_left{
    .font_size1();
    margin-left: 1vh;
    font-weight: 600
}
.div_button_center{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    border-top: 1px rgb(235; 237; 240) solid;
    padding-top: 1%
}

.table-column{
    display: flex;
    align-items: center;
    justify-content: center;
    .font_size09();
}

.table_overflow{
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
    color: #525252;
}
.tableLink{display: inline-block; margin: 0 5px;}
.tableLink u{color: #1890ff; text-decoration: none; cursor: pointer;}
.tableNoLink{display: inline-block; margin: 0 5px;}
.tableNoLink u{color: #ccc; text-decoration: none;}
.font_size_height{
    margin: 2% 2% 0% 0%;
}

// 设置表格属性
.tables{
    height: 100vh !important;
    background-color: #ffffff;
    .ant-table table{
        max-height: 80vh;
    }
    .ant-table table table{
        border: 1px solid #ecebeb;
        position: relative;
        left: -5px;
    }
    .ant-table table table tr th{
        padding:10px !important;
    }
    .ant-table table table tr td{
        padding:10px !important;
    }
    .ant-table-tbody > tr:nth-child(even) > td{
        background: #F9F9FA;
    }
    .ant-table-tbody > .ant-table-row-selected > td{
        background: #1890FF !important;
        color: #fff !important;
    }
    .ant-table-tbody > .ant-table-row-selected > td u{color: #ffffff !important;}
    .ant-table-tbody > .ant-table-row-selected > td span{color: #ffffff !important;}
    .ant-table-tbody > .ant-table-row-selected .table_overflow{
        color: #fff !important;
    }
    .ant-table-tbody > tr > td{
        height: 0.5vh;
        color: rgba(0,0,0,.65);
        max-height: 0.5vh !important;
        padding: 15px 0.6vw !important;
        border-right: 1px rgb(236, 235, 235) solid; 
        .table_overflow();
        .font_size09();
        color: #525252;
    }
    /deep/.ant-table-tbody > tr > th{
        white-space:nowrap;
        border-right: 1px rgb(236, 235, 235) solid; 
        color: #1F1F1F;
    }
    .ant-table-thead > tr th{
        color: rgba(0, 0, 0, 0.85);
        padding: 16px 0.6vw;
        white-space:nowrap;
        font-size: 16px;
    }
    .ant-table-column-sorters {
        flex: auto;
        .table-column();
        ::after{
            border-right: 1px rgb(236, 235, 235) solid !important; 
        }
    }
    .ant-table-column-sorters > span {
        .table-column();
        margin-right: 2%;
    }
    .ant-table-thead > tr > th{
        border-right: 1px rgb(236, 235, 235) solid; 
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0px;
    }
}

// 设置表格的属性
.insert_table{
    .ant-table-tbody > tr > td{
        padding: 1.26vh 16px;
        .font_size09();
    }
    .ant-table-thead > tr > th{
        padding: 6px 16px;
        .font_size09();
    }
}

// label
.drawer_from{
    .ant-form-item {
        font-size: 16px;
        &-label{
            margin-right: 10px;
            label{
                color: #000000;
                width: 100px;
                display:inline-block;  
                text-align-last: justify;
            }
        }
        &-control-input{
            position: relative;
            display: inline-block;
            align-items: center;
            min-height: 32px;
        }
    } 
    .ant-row{
        margin-bottom: -5px;
    }
}
.insert_table {
    .ant-form-item{
        margin: 2px 0;
        &-label > label{
            font-size: 16px;
        }
        &-control-input-content{
            font-size: 16px;
            word-break: break-all;
        }
        
    }
    .ant-divider > .ant-divider-horizontal > .ant-divider-with-text{
        margin: 5px 0;
    }
}

.left_title_table{
    width: 15vw;
    .ant-table-body{
        overflow-y: hidden !important;
    }
    .ant-table-cell{
        border-right: 1px solid #f0f0f0 !important;
    }
}

.divider{
    width: 100%;
    height: 0.5%;
    background-color: #f0f2f5
}

// 设置表格的属性
.report{
    .tables();
    .ant-table-tbody > tr > td{
        padding: 1.1vh 1vw !important;
    }
    .ant-table table{
        max-height: 77vh;
    }
}
.ant-table-thead > tr th{
    .font_size09()
}
// 设置表格的属性
.reportX{
    .tables();
    .ant-table-tbody > tr > td{
        padding: 0.95vh 1vw !important;
    }
    .ant-table table{
        max-height: 77vh;
    }
}

// 表格x轴滚动条样式
.scoll_table_x{
    &::-webkit-scrollbar {/*滚动条整体样式*/
        height: 10px;
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        border-radius: 5px;
        background: rgba(0,0,0,0.2);
        cursor: move;
    }
    &::-webkit-scrollbar-track {/*滚动条里面轨道*/
        border-radius: 0;
        background: rgba(0,0,0,0.1);
    }
}


.autoTableBox{
    &::-webkit-scrollbar {/*滚动条整体样式*/
        height: 10px;
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        border-radius: 5px;
        background: rgba(0,0,0,0.2);
        cursor: move;
    }
    &::-webkit-scrollbar-track {/*滚动条里面轨道*/
        border-radius: 0;
        background: rgba(0,0,0,0.1);
    }
  }