#earlyWarningCU{
  background-color: #ffffff;
}
#earlyWarningCU .top_div{
  padding: 10px;
  width: 100%;
  height: 150px;
  background-color: #ffffff;

}
#earlyWarningCU .top_div_left_div{
  width: 25%;
  height: 100%;
  float: left;
}
#earlyWarningCU .top_div_right_div{
  width: 75%;
  height: 100%;
  float: left;
}
#earlyWarningCU .bootm_div{
  width: 100%;
  height: 620px;
  float: left;
}
#earlyWarningCU .bootm_div_left_div{
  width: 49.5%;
  height: 100%;
  float: left;
}
#earlyWarningCU .bootm_div_right_div{
  width: 49.5%;
  height: 100%;
  float: left;
}
#earlyWarningCU .bootm_1_div{
  width: 100%;
  height: 80px;
  float: left;
}

// 顶部分割线
#earlyWarningCU .top_separate_div{
  width: 99.2%;
  height: 1px;
  background-color: #cccccc;
  margin-left: 4px;
  float: left;
}
#earlyWarningCU .bottom_separate_div{
  width: 1px;
  margin: 5px 7px 5px 0;
  height: 99%;
  background-color: #cccccc;
  float: left;
}
