#bottomThree{
  width: 100%;
  height: 100%;
}

#bottomThree .bottomThree_data_div_1_img{
  width: 100%;
  height: 100%;
  padding-top:16%;
  background-image: url(../../dataPanel/img/蓝色隐患图标.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto  100% !important;
}
#bottomThree .bottomThree_data_div_2_img{
  width: 100%;
  height: 68%;
  background-image: url(../../dataPanel/img/1.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
  position: relative;

}
